import { mapTitleToLabel } from '../../../../../../core/utils/helpers';

function generateClientDetails({ customer }) {
    const clientDetails = {
        formNumber: 1,
        data: [
            {
                formName: 'customer-reference',
                formLabel: 'ID:',
                formValue: `${(customer && customer.reference) || ''}`,
                required: true,
            },
            {
                formName: 'customer-title',
                formLabel: 'Title:',
                formValue: `${(customer && mapTitleToLabel(customer.title)) || ''}`,
                required: true,
            },
            {
                formName: 'customer-first_name',
                formLabel: 'First Name:',
                formValue: `${(customer && customer.first_name) || ''}`,
                required: true,
            },
            {
                formName: 'customer-last_name',
                formLabel: 'Last Name:',
                formValue: `${(customer && customer.last_name) || ''}`,
                required: true,
            },
            {
                formName: 'customer-address_line_1',
                formLabel: 'Address Line 1:',
                formValue: `${(customer && customer.home_address && customer.home_address.address_line_1) || ''}`,
                required: true,
            },
            {
                formName: 'customer-address_line_2',
                formLabel: 'Address Line 2:',
                formValue: `${(customer && customer.home_address && customer.home_address.address_line_2) || ''}`,
                required: false,
            },
            {
                formName: 'customer-address_line_3',
                formLabel: 'Address Line 3:',
                formValue: `${(customer && customer.home_address && customer.home_address.address_line_3) || ''}`,
                required: false,
            },
            {
                formName: 'customer-city_region',
                formLabel: 'City/Region:',
                formValue: `${(customer && customer.home_address && customer.home_address.city_region) || ''}`,
                required: true,
            },
            {
                formName: 'customer-postcode',
                formLabel: 'Postcode:',
                formValue: `${(customer && customer.home_address && customer.home_address.postcode) || ''}`,
                required: true,
            },
            {
                formName: 'customer-telephone',
                formLabel: 'Telephone:',
                formValue: `${(customer && customer.home_address && customer.home_address.telephone) || ''}`,
                required: false,
            },
            {
                formName: 'customer-mobile',
                formLabel: 'Mobile:',
                formValue: `${(customer && customer.home_address && customer.home_address.mobile) || ''}`,
                required: false,
            },
        ]
    };

    return clientDetails;
}


function generateNoteDetails({ customer }) {
    const noteDetails = {
        formNumber: 2,
        data: [
            {
                formName: 'customer-special_note',
                formLabel: 'Specials:',
                formValue: `${(customer && customer.special_note) || ''}`,
                required: true,
            },
            {
                formName: 'customer-pick_note',
                formLabel: 'Picking:',
                formValue: `${(customer && customer.pick_note) || ''}`,
                required: true,
            },
            {
                formName: 'customer-dispatch_note',
                formLabel: 'Dispatch:',
                formValue: `${(customer && customer.dispatch_note) || ''}`,
                required: true,
            },
        ]
    };

    return noteDetails;
}


function generateDoctorDetails({ doctor, practice }) {
    const doctorDetails = {
        formNumber: 3,
        data: [
            {
                formName: 'doctor-first_name',
                formLabel: 'First Name:',
                formValue: `${(doctor && doctor.first_name) || ''}`,
                required: true,
            },
            {
                formName: 'doctor-last_name',
                formLabel: 'Last Name:',
                formValue: `${(doctor && doctor.last_name) || ''}`,
                required: true,
            },
            {
                formName: 'practice-address_line_1',
                formLabel: 'Address Line 1:',
                formValue: `${(practice && practice.address_line_1) || ''}`,
                required: true,
            },
            {
                formName: 'practice-address_line_2',
                formLabel: 'Address Line 2:',
                formValue: `${(practice && practice.address_line_2) || ''}`,
                required: false,
            },
            {
                formName: 'practice-address_line_3',
                formLabel: 'Address Line 3:',
                formValue: `${(practice && practice.address_line_3) || ''}`,
                required: false,
            },
            {
                formName: 'practice-city_region',
                formLabel: 'City/Region:',
                formValue: `${(practice && practice.city_region) || ''}`,
                required: true,
            },
            {
                formName: 'practice-postcode',
                formLabel: 'Postcode:',
                formValue: `${(practice && practice.postcode) || ''}`,
                required: true,
            },
            {
                formName: 'doctor-phone_no',
                formLabel: 'Telephone:',
                formValue: `${(doctor && doctor.phone_no) || ''}`,
                required: true,
            },
            {
                formName: 'practice-fax',
                formLabel: 'Fax:',
                formValue: `${(practice && practice.fax) || ''}`,
                required: false,
            },
            {
                formName: 'doctor-email',
                formLabel: 'Email:',
                formValue: `${(doctor && doctor.email) || ''}`,
                required: true,
            },
        ]
    };

    return doctorDetails;
}

function generatePracticeDetails({ practice }) {
    const practiceDetails = {
        formNumber: 4,
        data: [
            {
                formName: 'practice-gp_reference',
                formLabel: 'GP Reference:',
                formValue: `${(practice && practice.gp_reference) || ''}`,
                required: true,
            },
            {
                formName: 'practice-title',
                formLabel: 'Practice Name:',
                formValue: `${(practice && practice.title) || ''}`,
                required: true,
            },
            {
                formName: 'practice-address_line_1',
                formLabel: 'Address Line 1:',
                formValue: `${(practice && practice.address_line_1) || ''}`,
                required: true,
            },
            {
                formName: 'practice-address_line_2',
                formLabel: 'Address Line 2:',
                formValue: `${(practice && practice.address_line_2) || ''}`,
                required: false,
            },
            {
                formName: 'practice-address_line_3',
                formLabel: 'Address Line 3:',
                formValue: `${(practice && practice.address_line_3) || ''}`,
                required: false,
            },
            {
                formName: 'practice-city_region',
                formLabel: 'City/Region:',
                formValue: `${(practice && practice.city_region) || ''}`,
                required: true,
            },
            {
                formName: 'practice-postcode',
                formLabel: 'Postcode:',
                formValue: `${(practice && practice.postcode) || ''}`,
                required: true,
            },
            {
                formName: 'practice-telephone',
                formLabel: 'Telephone:',
                formValue: `${(practice && practice.telephone) || ''}`,
                required: true,
            },
            {
                formName: 'practice-email',
                formLabel: 'Email:',
                formValue: `${(practice && practice.email) || ''}`,
                required: true,
            },
        ]
    };
    return practiceDetails;
}

function customerActiveOptions() {
    return [
        {
            label: 'Active',
            value: 1,
        },
        {
            label: 'Inactive',
            value: 0,
        },
        {
            label: 'All',
            value: null,
        },
    ];
}

export { generateClientDetails, generateNoteDetails, generateDoctorDetails, generatePracticeDetails, customerActiveOptions };
