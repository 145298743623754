import { checkDate } from '../../../../../../core/utils/helpers';
import values from '../../../../../../core/utils/values';

function generateOrders({ orderSearchResults }) {
    const valueLists = {
        idList: [],
        statusList: [],
        orderPlacedByList: [],
        createdAtList: [],
        dueAtList: [],
        reminderSentList: [],
        valueList: [],
        orderAddedByList: [],
    };

    orderSearchResults.map((orderSearchResult) => {
        valueLists.idList.push((orderSearchResult.id != null) ? orderSearchResult.id : null);
        valueLists.statusList.push((orderSearchResult.script_status != null) ? parseInt(orderSearchResult.script_status, 10) : null);
        valueLists.orderPlacedByList.push((orderSearchResult.createdByUserProfile?.full_name != null || orderSearchResult.createdByUserProfile?.username != null) ?
            (orderSearchResult.createdByUserProfile?.full_name || orderSearchResult.createdByUserProfile?.username) : 'Unavailable');
        valueLists.createdAtList.push(orderSearchResult.created_at != null ? checkDate(orderSearchResult.created_at, 'output', 'DD/MM/YYYY') : 'N/a');
        valueLists.dueAtList.push(orderSearchResult.due_date != null ? checkDate(orderSearchResult.due_date, 'output', 'DD/MM/YYYY') : 'N/a');
        // valueLists.reminderSentList.push(orderSearchResult.letters.filter(x => x.type === 3));
        valueLists.reminderSentList.push(orderSearchResult.letters ? orderSearchResult.letters.filter(x => x.type === 3) : []);
        valueLists.valueList.push((orderSearchResult.orderValue != null) ? `£${Math.round(orderSearchResult.orderValue * 100) / 100}` : 'N/a');
        return orderSearchResult;
    });

    return [
        {
            fieldName: 'order_id',
            fieldLabel: 'Order Id',
            fieldValues: valueLists.idList,
        },
        {
            fieldName: 'status',
            fieldLabel: 'Status',
            fieldValues: valueLists.statusList,
        },
        {
            fieldName: 'order_placed_by',
            fieldLabel: 'Order Placed By',
            fieldValues: valueLists.orderPlacedByList,
        },
        {
            fieldName: 'created_at',
            fieldLabel: 'Created At',
            fieldValues: valueLists.createdAtList,
        },
        {
            fieldName: 'due_at',
            fieldLabel: 'Due At',
            fieldValues: valueLists.dueAtList,
        },
        {
            fieldName: 'reminder_sent',
            fieldLabel: 'Reminder Sent',
            fieldValues: valueLists.reminderSentList,
        },
        {
            fieldName: 'value',
            fieldLabel: 'Value',
            fieldValues: valueLists.valueList,
        },
        {
            fieldName: 'repeat_edit',
            fieldLabel: '',
            fieldValues: valueLists.idList,
        }
    ];
}

function generateItems({ itemList, activeOrderId }) {
    const valueLists = {
        orderIdList: [],
        itemIdList: [],
        scriptStatusList: [],
        deliveryStatusList: [],
        quantityList: [],
        quantityAllocatedList: [],
        descriptionList: [],
        scriptReminders: [],
        valueList: [],
        deliveryDateList: [],
        dispatchDateList: [],
        dueDateList: [],
        totalValue: [],
        uomList: [],
        itemAddedByList: [],
    };

    itemList.map((item) => {
        const isScriptRemindersVisible = item.order.script_status !== '0';
        valueLists.orderIdList.push(activeOrderId);
        valueLists.itemIdList.push((item.product.sku != null) ? item.product.sku : null);
        valueLists.scriptStatusList.push((item.script_status != null) ? item.script_status : null); //
        valueLists.deliveryStatusList.push((item.delivery_status != null) ? item.delivery_status : null); //
        valueLists.quantityList.push((item.quantity != null) ? item.quantity : 'N/a');
        valueLists.quantityAllocatedList.push((item.product.quantity != null) ? item.product.quantity : null); //
        valueLists.descriptionList.push((item.product.description != null) ? item.product.description : 'N/a');
        // valueLists.scriptReminders.push(isScriptRemindersVisible ? { reminder: Number(item.quantity) - item.quantity_matched, matched: item.quantity_matched } : { reminder: 0, matched: 0 });
        valueLists.scriptReminders.push(isScriptRemindersVisible
            ? { reminder: Number(item.quantity) - item.quantity_matched, matched: item.quantity_matched, requiresPrescription: item.product.requires_prescription }
            : { reminder: 0, matched: 0, requires_prescription: item.product.requires_prescription });
        valueLists.deliveryDateList.push((item.delivery_date) ? checkDate(item.delivery_date, 'output', 'DD/MM/YYYY') : 'N/a');
        valueLists.dispatchDateList.push((item.dispatch_date) ? checkDate(item.dispatch_date, 'output', 'DD/MM/YYYY') : null); //
        valueLists.dueDateList.push((item.due_date) ? checkDate(item.due_date, 'output', 'DD/MM/YYYY') : 'N/a');
        valueLists.valueList.push((item.product.price) ? `£${Math.round(item.product.price * 100) / 100}` : 'N/a');
        valueLists.totalValue.push((item.product.price) ? `£${Math.round(item.product.price * 100 * item?.quantity) / 100}` : 'N/a');
        valueLists.uomList.push((item.product.unit_measure) ? item.product.unit_measure : 'Not Available');
        valueLists.itemAddedByList.push((item.createdByUserProfile?.full_name != null || item.createdByUserProfile?.username != null) ?
            (item.createdByUserProfile?.full_name || item.createdByUserProfile?.username) : 'Unavailable');
        return item;
    });

    return [
        {
            fieldName: 'select',
            fieldLabel: '',
            fieldValues: valueLists.orderIdList,
        },
        {
            fieldName: 'order_id',
            fieldLabel: 'Order Id',
            fieldValues: valueLists.orderIdList,
        },
        {
            fieldName: 'item_id',
            fieldLabel: 'Item Id',
            fieldValues: valueLists.itemIdList,
        },
        {
            fieldName: 'description',
            fieldLabel: 'Description',
            fieldValues: valueLists.descriptionList,
        },
        {
            fieldName: 'script_processing',
            fieldLabel: 'Script / Reminders',
            fieldValues: valueLists.scriptReminders,
        },
        {
            fieldName: 'item_added_by',
            fieldLabel: 'Item Added By',
            fieldValues: valueLists.itemAddedByList,
        },
        {
            fieldName: 'delivery_status',
            fieldLabel: 'Delivery Status',
            fieldValues: valueLists.deliveryStatusList,
        },
        // { // this functionaly needs to be resued in the future when we have the ability to allocate stock
        //     fieldName: 'quantity_allocated',
        //     fieldLabel: 'Quantity Allocated',
        //     fieldValues: valueLists.quantityAllocatedList,
        // },
        {
            fieldName: 'quantity_needed',
            fieldLabel: 'Quantity',
            fieldValues: valueLists.quantityList,
        },
        {
            fieldName: 'uom',
            fieldLabel: 'UOM',
            fieldValues: valueLists.uomList,
        },
        {
            fieldName: 'delivery_date',
            fieldLabel: 'Delivery Date',
            fieldValues: valueLists.deliveryDateList,
        },
        {
            fieldName: 'due_date',
            fieldLabel: 'Due Date',
            fieldValues: valueLists.dueDateList,
        },
        {
            fieldName: 'dispatch_date',
            fieldLabel: 'Dispatch Date',
            fieldValues: valueLists.dispatchDateList,
        },
        {
            fieldName: 'value',
            fieldLabel: 'Unit value',
            fieldValues: valueLists.valueList,
        },
        {
            fieldName: 'total_value',
            fieldLabel: 'Total value',
            fieldValues: valueLists.totalValue,
        },
    ];
}

function orderStatusOptions() {
    return [
        {
            label: 'No script',
            value: 0,
        },
        {
            label: 'Script partly received',
            value: 1,
        },
        {
            label: 'Processed',
            value: 2,
        },
    ];
}

function itemScriptStatusOptions() {
    return [
        {
            label: 'Matched',
            value: values.ORDER_ITEM_STATUS_MATCHED,
        },
        {
            label: 'Clear',
            value: values.ORDER_ITEM_STATUS_CLEAR,
        },
        {
            label: 'Written off',
            value: values.ORDER_ITEM_STATUS_WRITTEN_OFF,
        },
        {
            label: 'Item cancelled',
            value: values.ORDER_ITEM_STATUS_ITEM_CANCELLED,
        },
        {
            label: 'Issue reminder',
            value: values.ORDER_ITEM_STATUS_REMINDER_ISSUED,
        },
    ];
}

function itemDeliveryStatusOptions() {
    return [
        {
            label: 'Picking',
            value: 0,
        },
        {
            label: 'Dispatch',
            value: 1,
        },
        {
            label: 'Backorder',
            value: 2,
        },
        {
            label: 'Backorder',
            value: 3,
        },
        {
            label: 'Cancelled',
            value: 4,
        },
    ];
}

export { generateOrders, generateItems, orderStatusOptions, itemScriptStatusOptions, itemDeliveryStatusOptions };
