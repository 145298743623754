/* eslint-disable no-nested-ternary */

import React, { Component, } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { DatePicker, Input, Checkbox, InputNumber, Empty, Spin, Modal } from 'antd';
import Select from 'react-select';
import { isEqual, isEmpty, isArray, } from 'underscore';
import { browserHistory } from 'react-router';
import IssueLetterModal from './IssueLetterModal/index';
import styles from './styles';
import greyStatusIcon from '../../../../../../assets/images/grey-status-icon.png';
import redStatusIcon from '../../../../../../assets/images/red-status-icon.png';
import greenStatusIcon from '../../../../../../assets/images/green-status-icon.png';
import yellowStatusIcon from '../../../../../../assets/images/yellow-status-icon.png';
import orderVisibleIcon from '../../../../../../assets/images/order-visible-icon.png';
import orderInvisibleIcon from '../../../../../../assets/images/order-invisible-icon.png';
import repeatOrderIcon from '../../../../../../assets/images/repeat-order-icon.png';
import editIcon from '../../../../../../assets/images/edit-icon.png';
import { generateOrders, generateItems, orderStatusOptions, itemScriptStatusOptions, itemDeliveryStatusOptions } from './dataGenerators';
import { checkDate, debounce } from '../../../../../../core/utils/helpers';
import * as customerActions from '../../../../../../core/customer/customerActions';
import * as orderActions from '../../../../../../core/order/orderActions';
import values from '../../../../../../core/utils/values';
import OrderSummaryModal from './OrderSummaryModal';

/* eslint-disable no-extend-native, no-plusplus */
Array.prototype.unique = function () {
    const a = this.concat();
    for (let i = 0; i < a.length; ++i) {
        for (let j = i + 1; j < a.length; ++j) {
            if (a[i] === a[j]) { a.splice(j--, 1); }
        }
    }

    return a;
};

class GridviewSection extends Component {
    constructor(props) {
        super(props);
        const { orderSearchResults } = this.props;

        this.state = {
            orderSearchResults,
            filters: {},
            selectedItems: [],
            issueLetterOrderId: null,
            showOrderSummaryModal: false,
            selectedSummaryOrderId: null,
        };

        this.handleCloseOrderSummaryModal = this.handleCloseOrderSummaryModal.bind(this);
        this.handleStatusIconClick = this.handleStatusIconClick.bind(this);
    }

    componentDidMount() {
        const {
            actions,
        } = this.props;
        const { orderSearchResults } = this.state;

        this.handleSearchRequest();
        if (orderSearchResults[0] && orderSearchResults[0].id) actions.setActiveSearchOrderIdOfflineRequest(orderSearchResults[0].id);
    }

    componentDidUpdate(prevProps, prevState) {
        const { customer } = this.props;
        const {
            orderSearchResults,
            actions,
            activeTab,
            isSearchingCustomerOrdersByFilters,
            summaryPageRerenderToggle
        } = this.props;

        // Update the state with new redux state values
        if (!isEqual(customer, prevProps.customer)) {
            this.handleSearchRequest();
            this.setState({ filters: {}, selectedItems: [], });
            if (orderSearchResults[0] && orderSearchResults[0].id) actions.setActiveSearchOrderIdOfflineRequest(orderSearchResults[0].id);
        }

        if (!isEqual(isSearchingCustomerOrdersByFilters, prevProps.isSearchingCustomerOrdersByFilters) && isSearchingCustomerOrdersByFilters === false) {
            this.setState({ orderSearchResults });
            if (orderSearchResults[0] && orderSearchResults[0].id) actions.setActiveSearchOrderIdOfflineRequest(orderSearchResults[0].id);
        }

        if (!isEqual(summaryPageRerenderToggle, prevProps.summaryPageRerenderToggle)) {
            this.setState({ orderSearchResults });
        }

        if (!isEqual(activeTab, prevProps.activeTab) && activeTab === '1') {
            this.handleSearchRequest();
            this.setState({ filters: {}, selectedItems: [], });
        }
    }

    handleCloseOrderSummaryModal = () => {
        this.setState({
            selectedSummaryOrderId: null,
            showOrderSummaryModal: false
        });
    }

    handleStatusIconClick = (orderId) => {
        this.setState({
            selectedSummaryOrderId: orderId,
            showOrderSummaryModal: true
        });
    }

    handleSearchRequest = () => {
        const { params, actions } = this.props;
        const { filters, } = this.state;
        const filtersBuffer = JSON.parse(JSON.stringify(filters));

        if (filters.status && ['object'].includes(typeof filters.status)) {
            // filtersBuffer.status = filters.status.value || null;
            filtersBuffer.status = filters.status.value === 0 ? 0 : (filters.status.value || null);
            filtersBuffer.from_date = checkDate(filtersBuffer.from_date, 'output', 'YYYY-MM-DD hh:mm:ss');
            filtersBuffer.to_date = checkDate(filtersBuffer.to_date, 'output', 'YYYY-MM-DD hh:mm:ss');
        }

        if (params.id && params.id !== '0') actions.searchCustomerOrdersByFiltersRequest({ id: params.id, filters: filtersBuffer, limit: 10000, });
    }

    handleClear = () => {
        const { customer, actions, type } = this.props;
        const { filters, } = this.state;
        const filtersBuffer = JSON.parse(JSON.stringify(filters));

        Object.keys(filtersBuffer).forEach((key) => {
            filtersBuffer[key] = null;
        });

        this.setState({ filters: filtersBuffer }, () => {
            if (type === 'order' && customer.id) actions.searchCustomerOrdersByFiltersRequest({ id: customer.id, filters: filtersBuffer, limit: 10000, });
        });
    }

    handleSetSearchInput = ({ event, type }) => {
        const { filters } = this.state;

        debounce(this.setState({ filters: { ...filters, id: event.target.value, } }, () => {
            if (type === 'order') this.handleSearchRequest();
        }).bind(this), 50);
    }

    _renderFilterBar = () => {
        const { type } = this.props;
        const { filters } = this.state;

        return (
            <div style={{ width: '100%' }}>
                <p style={styles.filterBarTitle}>{type === 'order' ? 'Orders' : 'Items'}</p>
                <div style={styles.filterBarContainer}>
                    <div style={styles.filterGroup}>
                        <p
                            style={styles.filterLabel}>{type === 'order' ? 'Status:' : 'Script Status:'}
                        </p>
                        <Select
                            styles={{
                                ...styles.selectInput,
                                valueContainer: (provided, state) => ({
                                    ...provided,
                                    height: '25px',
                                    minWidth: '130px',
                                    padding: '0 6px'
                                }),
                            }}
                            isSearchable={false}
                            value={filters.status}
                            onChange={(option) => {
                                this.setState({ filters: { ...filters, status: option, } }, () => {
                                    if (type === 'order') this.handleSearchRequest();
                                });
                            }}
                            options={type === 'order' ?
                                orderStatusOptions().map(option => ({
                                    label: option.label,
                                    value: option.value,
                                }))
                                :
                                itemScriptStatusOptions().map(option => ({
                                    label: option.label,
                                    value: option.value,
                                }))
                            } />
                    </div>
                    <div style={styles.filterGroup}>
                        <p
                            style={styles.filterLabel}>From:
                        </p>
                        <DatePicker
                            format="DD/MM/YYYY"
                            value={checkDate(filters.from_date, 'input', 'DD/MM/YYYY')}
                            onChange={(date, dateString) => {
                                this.setState({ filters: { ...filters, from_date: dateString, } }, () => {
                                    if (type === 'order') this.handleSearchRequest();
                                });
                            }}
                            style={{ width: 120, height: 24, }} />
                    </div>
                    <div style={styles.filterGroup}>
                        <p
                            style={styles.filterLabel}>To:
                        </p>
                        <DatePicker
                            format="DD/MM/YYYY"
                            value={checkDate(filters.to_date, 'input', 'DD/MM/YYYY')}
                            onChange={(date, dateString) => {
                                this.setState({ filters: { ...filters, to_date: dateString, } }, () => {
                                    if (type === 'order') this.handleSearchRequest();
                                });
                            }}
                            style={{ width: 120, height: 24, }} />
                    </div>
                    <div style={styles.filterGroup}>
                        <p
                            style={styles.filterLabel}>{type === 'order' ? 'Order No' : 'Item No'}:
                        </p>
                        <Input
                            value={filters.id}
                            onChange={event => this.handleSetSearchInput({ event, type })}
                            placeholder={'Type'}
                            style={{ width: 240, height: 24, }} />
                    </div>
                    <a onClick={() => this.handleClear()}>clear</a>
                </div>
            </div>
        );
    }

    _renderTableHeaderElements = (value) => {
        const { type, activeOrderId } = this.props;
        const { orderSearchResults, selectedItems } = this.state;
        const orderSearchResult = orderSearchResults.find(orderSearchResult => orderSearchResult.id === activeOrderId) || {};
        let itemList = orderSearchResult.orderItems || [];

        itemList = this.filterItems({ itemList }) || [];

        switch (`${type}_${value.fieldName}`) {
        case 'item_select':
            return (
                <th
                    key={`${type}_${value.fieldName}`}
                    style={styles.tableHeaderElement}>
                    <Checkbox
                        checked={itemList.length === selectedItems.length}
                        onChange={event => this.handleSelectedAllItems({ checked: event.target.checked, })} />
                </th>
            );
        default:
            return (
                <th
                    key={`${type}_${value.fieldName}`}
                    style={{ ...styles.tableHeaderElement, }}>
                    {value.fieldLabel.toUpperCase()}
                </th>
            );
        }
    }

    _renderTableBodyRowElement = ({ column, fieldName, orderId, itemId, }) => {
        const { type, actions, activeOrderId } = this.props;
        const { orderSearchResults, selectedItems } = this.state;
        const activeOrder = orderSearchResults.find(orderSearchResult => orderSearchResult.id === activeOrderId) || {};

        switch (`${type}_${fieldName}`) {
        // Order
        case 'order_order_id': {
            return (
                <td
                    key={`${orderId}_${fieldName}`}
                    style={{ ...styles.tableBodyElement, fontWeight: 'bold', }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                        {column}
                        <img
                            alt="order-icon"
                            onClick={() => {
                                actions.setActiveSearchOrderIdOfflineRequest(orderId);
                            }}
                            style={{
                                width: 15,
                                height: 15,
                                marginRight: 5,
                                marginLeft: 5,
                            }}
                            src={(orderId === activeOrder.id) ? orderVisibleIcon : orderInvisibleIcon} />
                    </div>
                </td>
            );
        }
        case 'order_order_dispatched': {
            return (
                <td
                    key={`${orderId}_${fieldName}`}
                    style={{ ...styles.tableBodyElement, fontWeight: 'bold', }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                        {column === 3 ? 'Yes' : null }
                    </div>
                </td>
            );
        }
        case 'order_status': {
            const orderStatusValue = orderStatusOptions().find(option => option.value === column);
            let image = null;
            switch (column) {
            case 0:
                image = redStatusIcon;
                break;
            case 1:
                image = yellowStatusIcon;
                break;
            case 2:
                image = greenStatusIcon;
                break;
            default:
                image = greyStatusIcon;
                break;
            }

            return (
                <td
                    key={`${orderId}_${fieldName}`}
                    style={{ ...styles.tableBodyElement, }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                        {image &&
                        <div onClick={() => this.handleStatusIconClick(orderId)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', }}>
                            <img
                                style={{
                                    width: 15,
                                    height: 15,
                                    marginRight: 8,
                                    marginLeft: 5,
                                }}
                                src={image}
                                alt="status-icon" />
                            <span>{orderStatusValue.label}</span>
                        </div>
                        }
                    </div>
                </td>
            );
        }
        case 'order_reminder_sent': {
            const text = column.filter(x => x.user && x.user.username).map(x => `${checkDate(x.created_at, 'output', 'DD/MM/YYYY')} by ${x.user && x.user.username}`).join(', ');
            return (
                <td
                    key={`${orderId}_${fieldName}`}
                    style={{ ...styles.tableBodyElement, }}>
                    {
                        (text && text !== '') ?
                            text :
                            (
                                (isArray(column) && column.length !== 0) ?
                                    `Yes (${column.length})` :
                                    'No'
                            )
                    }
                </td>
            );
        }
        case 'order_repeat_edit': {
            return (
                <td
                    key={`${orderId}_${fieldName}`}
                    style={styles.tableBodyElement}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', }}>
                        <div
                            onClick={() => this.handleRepeat(orderId)}
                            style={styles.buttonWrapper}>
                            <img
                                alt="repeat-order-icon"
                                style={{
                                    width: 15,
                                    height: 15,
                                    marginRight: 5,
                                    marginLeft: 5,
                                }}
                                src={repeatOrderIcon} />
                        </div>
                        <div
                            onClick={() => this.handleEdit(orderId)}
                            style={styles.buttonWrapper}>
                            <img
                                alt="edit-order-icon"
                                style={{
                                    width: 15,
                                    height: 15,
                                    marginRight: 5,
                                    marginLeft: 5,
                                }}
                                src={editIcon} />
                        </div>
                    </div>
                </td>
            );
        }
        case 'order_due_at':
            return (
                <td
                    key={`${orderId}_${fieldName}`}
                    style={styles.tableBodyElement}>
                    <DatePicker
                        format="DD/MM/YYYY"
                        placeholder={'Pick'}
                        value={checkDate(column, 'input', 'DD/MM/YYYY')}
                        onChange={(date, dateString) => this.handleSetDueAtDate({ dateString, orderId })}
                        style={{ width: 120, height: 24, }} />
                </td>
            );
        // Item
        case 'item_select':
            return (
                <td
                    key={`${itemId}_${fieldName}`}
                    style={styles.tableBodyElement}>
                    <Checkbox
                        checked={selectedItems.includes(itemId)}
                        onChange={event => this.handleSetSelectedItem({ checked: event.target.checked, orderId, itemId, })}
                        style={{ width: 24, height: 24, }} />
                </td>
            );
        case 'item_order_id':
            return (
                <td
                    key={`${itemId}_${fieldName}`}
                    style={{ ...styles.tableBodyElement, fontWeight: 'bold', }}>
                    {column}
                </td>
            );
        case 'item_description':
            return (
                <td
                    key={`${itemId}_${fieldName}`}
                    style={{ ...styles.tableBodyElement, minWidth: 150, }}>
                    {column}
                </td>
            );
        case 'item_delivery_status': {
            const itemDeliveryStatusValue = itemDeliveryStatusOptions().find(option => option.value === column);
            return (
                <td
                    key={`${itemId}_${fieldName}`}
                    style={{ ...styles.tableBodyElement }}>
                    {itemDeliveryStatusValue?.value === values.ITEM_DELIVERY_STATUS_DISPATCHED ?
                        <div style={styles.dispatchText}>
                                Dispatched
                        </div>
                        : <span>{itemDeliveryStatusValue?.label}</span>
                    }
                </td>
            );
        }
        case 'item_quantity_allocated':
            return (
                <td
                    key={`${itemId}_${fieldName}`}
                    style={styles.tableBodyElement}>
                    <InputNumber
                        value={column}
                        size="small"
                        style={{ width: 45, height: 24, }}
                        onChange={value => this.handleSetQuantityAllocated({ value, orderId, itemId, })}
                        min={0}
                        max={10}
                        defaultValue={3} />
                </td>
            );
        case 'item_dispatch_date':
            return (
                <td
                    key={`${itemId}_${fieldName}`}
                    style={styles.tableBodyElement}>
                    <DatePicker
                        format="DD/MM/YYYY"
                        placeholder={'Pick'}
                        value={checkDate(column, 'input', 'DD/MM/YYYY')}
                        onChange={(date, dateString) => this.handleSetDispatchDate({ date, dateString, orderId, itemId, })}
                        style={{ width: 120, height: 24, }} />
                </td>
            );
        case 'item_script_processing':
            // eslint-disable-next-line no-case-declarations
            const { matched, reminder, requiresPrescription } = column;
            // eslint-disable-next-line no-case-declarations
            const matchedStyle = {
                color: 'rgba(20, 149, 102, 1)',
                backgroundColor: 'rgba(20, 149, 102, 0.4)',
                padding: '3px 6px',
                borderRadius: '4px',
                fontWeight: 'bold',
            };
            // eslint-disable-next-line no-case-declarations
            const reminderStyle = {
                color: 'rgba(242,157,56,1)',
                backgroundColor: 'rgba(242,157,56,0.4)',
                padding: '3px 6px',
                borderRadius: '4px',
                fontWeight: 'bold',
            };
            // eslint-disable-next-line no-case-declarations
            const zeroStyle = {
                color: 'rgba(0,0,0,1)',
                backgroundColor: '#f5f5f5',
                padding: '3px 6px',
                borderRadius: '4px',
            };
            // eslint-disable-next-line no-case-declarations
            const noScriptStyle = {
                padding: '3px 6px',
                borderRadius: '4px',
                fontSize: '12px',
                fontWeight: 'bold',
                backgroundColor: '#f5f5f5',
            };
            // eslint-disable-next-line no-case-declarations
            const noPrescriptionStyle = {
                padding: '3px 6px',
                borderRadius: '4px',
                fontSize: '15px',
                fontWeight: 'bold',
                color: 'rgba(242,157,56,1)',
                backgroundColor: '#f5f5f5',
            };
            // shows N/A if requires_prescription = 0
            if (requiresPrescription === 0) {
                return (
                    <td
                        key={`${itemId}_${fieldName}`}
                        style={styles.tableBodyElement}>
                        <span style={noPrescriptionStyle}>N/A</span>
                    </td>
                );
            }

            if (matched === 0 && reminder === 0) {
                return (<td
                    key={`${itemId}_${fieldName}`}
                    style={styles.tableBodyElement}>
                    <span style={noScriptStyle}>No script</span>
                </td>);
            }
            return (
                <td
                    key={`${itemId}_${fieldName}`}
                    style={styles.tableBodyElement}>
                    <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                        <div style={matched > 0 ? matchedStyle : zeroStyle}>{matched}</div> <span>/</span> <div style={reminder > 0 ? reminderStyle : zeroStyle}>{reminder}</div>
                    </div>
                </td>
            );
        default:
            return (
                <td
                    key={`${itemId}_${fieldName}`}
                    style={{ ...styles.tableBodyElement, }}>
                    {column}
                </td>
            );
        }
    }

    transpose = a => Object.keys(a[0]).map(c => a.map(r => r[c]))

    handleSetOrderStatus = ({ option, orderId, }) => {
        const { orderSearchResults } = this.state;
        const orderSearchResultsBuffer = JSON.parse(JSON.stringify(orderSearchResults));
        const { actions } = this.props;
        const index = orderSearchResultsBuffer.findIndex(orderSearchResult => orderSearchResult.id === orderId);

        // Set the order_status for the current order for simplicity.
        orderSearchResultsBuffer[index].order_status = option.value;

        // Update the local state
        this.setState({ orderSearchResults: orderSearchResultsBuffer });

        // Update the database
        actions.updateCustomerOrderRequest({
            ...orderSearchResultsBuffer[index],
        });
    }

    handleSetQuantityAllocated = ({ value, orderId, itemId, }) => {
        const { orderSearchResults, selectedItems } = this.state;
        const orderSearchResultsBuffer = JSON.parse(JSON.stringify(orderSearchResults));
        const { actions } = this.props;
        const index = orderSearchResultsBuffer.findIndex(orderSearchResult => orderSearchResult.id === orderId);
        // Loop over items
        const itemIdList = selectedItems.concat([itemId]).unique();
        itemIdList.map((id) => {
            const index2 = orderSearchResultsBuffer[index].orderItems.findIndex(orderItem => orderItem.id === id);
            orderSearchResultsBuffer[index].orderItems[index2].product.quantity = value;
            // Send request to change item
            actions.updateOrderItemRequest({
                ...orderSearchResultsBuffer[index].orderItems[index2],
            });
        });

        this.setState({ orderSearchResults: orderSearchResultsBuffer });
    }

    handleSetDispatchDate = ({ date, dateString, orderId, itemId, }) => {
        const { orderSearchResults, selectedItems } = this.state;
        const orderSearchResultsBuffer = JSON.parse(JSON.stringify(orderSearchResults));
        const { actions } = this.props;
        const index = orderSearchResultsBuffer.findIndex(orderSearchResult => orderSearchResult.id === orderId);
        // Loop over items
        const itemIdList = selectedItems.concat([itemId]).unique();
        itemIdList.map((id) => {
            const index2 = orderSearchResultsBuffer[index].orderItems.findIndex(orderItem => orderItem.id === id);
            orderSearchResultsBuffer[index].orderItems[index2].dispatch_date = checkDate(dateString, 'output', 'YYYY-MM-DD hh:mm:ss');
            // Send request to change item
            actions.updateOrderItemRequest({
                ...orderSearchResultsBuffer[index].orderItems[index2],
            });
        });

        this.setState({ orderSearchResults: orderSearchResultsBuffer });
    }

    handleSetDueAtDate = ({ dateString, orderId }) => {
        const { orderSearchResults } = this.state;
        const orderSearchResultsBuffer = JSON.parse(JSON.stringify(orderSearchResults));
        const { actions } = this.props;

        const index = orderSearchResultsBuffer.findIndex(orderSearchResult => orderSearchResult.id === orderId);

        if (index !== -1) {
            orderSearchResultsBuffer[index].due_date = checkDate(dateString, 'output', 'YYYY-MM-DD hh:mm:ss');
            this.setState({ orderSearchResults: orderSearchResultsBuffer });
            actions.updateCustomerOrderRequest({
                ...orderSearchResultsBuffer[index],
            });
        }
    }

    handleSetSelectedItem = ({ checked, orderId, itemId, }) => {
        const { selectedItems } = this.state;

        if (checked === true) {
            selectedItems.push(itemId);
            this.setState({ selectedItems });
        } else {
            selectedItems.splice(selectedItems.indexOf(itemId), 1);
            this.setState({ selectedItems });
        }
    }

    handleSelectedAllItems = ({ checked, }) => {
        const { activeOrderId } = this.props;
        const { orderSearchResults } = this.state;
        const itemList = orderSearchResults.find(orderSearchResult => orderSearchResult.id === activeOrderId).orderItems || [];
        if (checked === true) {
            this.setState({ selectedItems: itemList.map(item => item.id) });
        } else {
            this.setState({ selectedItems: [] });
        }
    }

    filterItems = ({ itemList }) => {
        const { filters, } = this.state;
        const filtersBuffer = JSON.parse(JSON.stringify(filters));

        if (filters.status && ['object'].includes(typeof filters.status)) {
            filtersBuffer.status = filters.status.value || null;
        }

        const itemListBuffer = itemList.filter((item) => {
            if (filtersBuffer.status && parseInt(item.script_status, 10) !== parseInt(filtersBuffer.status, 10)) return false;
            if (filtersBuffer.id && !String(item.id).startsWith(filtersBuffer.id)) return false;
            if (filtersBuffer.from_date && item.created_at < checkDate(filtersBuffer.from_date, 'output', 'YYYY-MM-DD hh:mm:ss')) return false;
            if (filtersBuffer.to_date && item.created_at > checkDate(filtersBuffer.to_date, 'output', 'YYYY-MM-DD hh:mm:ss')) return false;
            return true;
        });

        return itemListBuffer;
    }

    handleEdit = (orderId) => {
        const { customer } = this.props;
        const customerId = customer && customer.id;

        browserHistory.push({
            pathname: `/order/${orderId}/customer/${customerId}`,
            state: {
                from: 'customer-summary-edit',
            }
        });
    }

    handleRepeat = (orderId) => {
        const { customer } = this.props;
        const customerId = customer && customer.id;

        browserHistory.push({
            pathname: `/order/0/customer/${customerId}`,
            state: {
                from: 'customer-summary-repeat',
                orderId
            }
        });
    }

    _renderTableBody = (data) => {
        const { type } = this.props;
        const matrix = [];
        const fieldNames = [];
        data.map((element) => {
            matrix.push(element.fieldValues);
            fieldNames.push(element.fieldName);
        });
        const transpose = this.transpose(matrix);

        return (
            <tbody style={styles.tableBody}>
                {transpose.map((row, rowIndex) => (
                    <tr
                        key={rowIndex}
                        style={styles.tableBodyRow}>
                        {row.map((column, columnIndex) => {
                            let orderId = 0;
                            let itemId = 0;
                            switch (type) {
                            case 'order':
                                orderId = row[0];
                                break;
                            case 'item':
                                itemId = row[2];
                                orderId = row[1];
                                break;
                            default:
                                break;
                            }
                            return this._renderTableBodyRowElement({
                                column,
                                fieldName: fieldNames[columnIndex],
                                orderId,
                                itemId,
                            });
                        })}
                    </tr>))}
                <tr>
                    <td style={{ height: 200 }} />
                </tr>
            </tbody>
        );
    }

    _renderTable = () => {
        const { type, activeOrderId } = this.props;
        const { orderSearchResults } = this.state;

        let data = [];
        if (orderSearchResults !== 'reset' && isArray(orderSearchResults) && !isEmpty(orderSearchResults)) {
            switch (type) {
            case 'order':
                data = generateOrders({ orderSearchResults });
                break;
            case 'item': {
                const orderSearchResult = orderSearchResults.find(orderSearchResult => orderSearchResult.id === activeOrderId) || {};
                let itemList = orderSearchResult.orderItems || [];
                itemList = this.filterItems({ itemList }) || [];
                data = generateItems({ itemList, activeOrderId });
                break;
            }
            default:
                break;
            }
        }

        const shouldRenderTableContent = data && isArray(data) && data[0] && isArray(data[0].fieldValues) && !isEmpty(data[0].fieldValues);

        return (
            <table
                style={styles.table}>
                {shouldRenderTableContent ?
                    <React.Fragment>
                        <thead style={styles.tableHeader}>
                            <tr style={styles.tableHeaderRow}>
                                {data.map(value => (
                                    this._renderTableHeaderElements(value)
                                ))}
                            </tr>
                        </thead>
                        {this._renderTableBody(data)}
                    </React.Fragment>
                    :
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No items'} />
                }
            </table>
        );
    }

    render() {
        const {
            height,
            isSearchingCustomerOrdersByFilters,
            isUpdatingSummaryOrder,
            isUpdatingOrderItem,
            type
        } = this.props;
        const {
            issueLetterOrderId,
            showOrderSummaryModal,
            selectedSummaryOrderId
        } = this.state;

        let gridviewContainerStyles = styles.gridviewContainer;
        if (height || height === 0) {
            gridviewContainerStyles = { ...gridviewContainerStyles, height, minHeight: height };
        }

        return (
            <>
                <Modal
                    centered width={840}
                    footer={null}
                    visible={showOrderSummaryModal}
                    onCancel={this.handleCloseOrderSummaryModal}>
                    <OrderSummaryModal orderId={selectedSummaryOrderId} />
                </Modal>
                <div style={gridviewContainerStyles} className="customer_summary_gridview">
                    <Spin wrapperClassName="gridview-spin-container" spinning={isSearchingCustomerOrdersByFilters || (isUpdatingSummaryOrder && type === 'order') || (isUpdatingOrderItem && type === 'item')}>
                        {this._renderFilterBar()}
                        {this._renderTable()}
                    </Spin>
                    <IssueLetterModal
                        issueLetterOrderId={issueLetterOrderId}
                        setIssueReminder={click => this.setIssueReminder = click}
                        onSubmit={this.handleSearchRequest} />
                </div>
            </>
        );
    }
}

GridviewSection.defaultProps = {
    customer: {},
    orders: {},
    actions: {},
    type: 'order',
    activeTab: '1',
    activeOrderId: null,
    orderSearchResults: [],
    height: null,
    params: {},
};

GridviewSection.propTypes = {
    customer: PropTypes.object,
    orders: PropTypes.object,
    actions: PropTypes.object,
    type: PropTypes.string,
    activeTab: PropTypes.string,
    activeOrderId: PropTypes.number,
    height: PropTypes.number,
    orderSearchResults: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    summaryPageRerenderToggle: PropTypes.bool.isRequired,
    isSearchingCustomerOrdersByFilters: PropTypes.bool.isRequired,
    isUpdatingSummaryOrder: PropTypes.bool.isRequired,
    isUpdatingOrderItem: PropTypes.bool.isRequired,
    params: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        activeOrderId: state.customer.activeOrderId,
        summaryPageRerenderToggle: state.customer.summaryPageRerenderToggle,
        orderSearchResults: state.customer.orderSearchResults,
        isSearchingCustomerOrdersByFilters: state.customer.isSearchingCustomerOrdersByFilters,
        isUpdatingSummaryOrder: state.customer.isUpdatingSummaryOrder,
        isUpdatingOrderItem: state.customer.isUpdatingOrderItem,
        customer: state.customer.customer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...customerActions,
            ...orderActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(GridviewSection);
