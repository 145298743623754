import optionValues from './values';

// SELECT OPTIONS
const options = {
    YES_NO_OPTIONS: [
        { value: optionValues.YES, label: 'Yes' },
        { value: optionValues.NO, label: 'No' }
    ],

    STATUS_OPTIONS: [
        { value: optionValues.STATUS_ACTIVE, label: 'Active' },
        { value: optionValues.STATUS_INACTIVE, label: 'Inactive' },
    ],


    FULL_ORDER_STATUS_OPTIONS: [
        { value: optionValues.ORDER_STATUS_PENDING, label: 'Pending' },
        { value: optionValues.ORDER_STATUS_SCRIPT_WAITING, label: 'Waiting on Script' },
        { value: optionValues.ORDER_STATUS_WAITING_FOR_DISPATCH, label: 'Waiting for Disptach' },
        { value: optionValues.ORDER_STATUS_DISPATCHED, label: 'Dispatched' },
        { value: optionValues.ORDER_STATUS_PROCESSED, label: 'Processed' },
        { value: optionValues.ORDER_STATUS_SCRIPT_SUPPLIED, label: 'Script Provided' },
        { value: optionValues.ORDER_STATUS_WITHOUT_SCRIPT, label: 'Without Script' },
        { value: optionValues.ORDER_STATUS_DISPATCH, label: 'Disptached' },
        { value: optionValues.ORDER_STATUS_DISPATCH_WITHOUT_SCRIPT, label: 'Disptached without Script Provided' },
        { value: optionValues.ORDER_STATUS_SCRIPT_WAITING_DOCTOR, label: 'Script Waiting Doctor' },
        { value: optionValues.ORDER_STATUS_SCRIPT_DUE_CLIENT, label: 'Script Due Client' },
        { value: optionValues.ORDER_STATUS_SCRIPT_DUE_DOCTOR, label: 'Script Due Doctor' },
        { value: optionValues.ORDER_STATUS_ELECTRONIC_PRESCRIPTION, label: 'Electronic Subscription' },
        { value: optionValues.ORDER_STATUS_NO_SCRIPT, label: 'No Script' },
        { value: optionValues.ORDER_STATUS_SCRIPT_PARTLY_RECEIVED, label: 'Script Partly Received' },
        { value: optionValues.ORDER_STATUS_SCRIPT_RECEIVED, label: 'Script Received' },
        { value: optionValues.ORDER_STATUS_PARTLY_PROCESSED, label: 'Partly Processed' },
        { value: optionValues.ORDER_STATUS_CANCELLED, label: 'Cancelled' },
    ],

    ORDER_STATUS_OPTIONS: [
        { value: optionValues.ORDER_STATUS_PENDING, label: 'Pending' },
        { value: optionValues.ORDER_STATUS_SCRIPT_WAITING, label: 'Waiting on Script' },
        { value: optionValues.ORDER_STATUS_WAITING_FOR_DISPATCH, label: 'Waiting for Disptach' },
        { value: optionValues.ORDER_STATUS_DISPATCHED, label: 'Dispatched' },
        { value: optionValues.ORDER_STATUS_PROCESSED, label: 'Processed' },
        { value: optionValues.ORDER_STATUS_SCRIPT_SUPPLIED, label: 'Script Provided' },
        { value: optionValues.ORDER_STATUS_WITHOUT_SCRIPT, label: 'Without Script' },
        { value: optionValues.ORDER_STATUS_DISPATCH, label: 'Disptached' },
        { value: optionValues.ORDER_STATUS_DISPATCH_WITHOUT_SCRIPT, label: 'Disptached without Script Provided' },
        { value: optionValues.ORDER_STATUS_SCRIPT_WAITING_DOCTOR, label: 'Script Waiting Doctor' },
        { value: optionValues.ORDER_STATUS_SCRIPT_DUE_CLIENT, label: 'Script Due Client' },
        { value: optionValues.ORDER_STATUS_SCRIPT_DUE_DOCTOR, label: 'Script Due Doctor' },
        { value: optionValues.ORDER_STATUS_ELECTRONIC_PRESCRIPTION, label: 'Electronic Subscription' },
    ],

    PRESCRIPTION_STATUS_OPTIONS: [
        { value: optionValues.ORDER_STATUS_SCRIPT_WAITING, label: 'Waiting on Script' },
        { value: optionValues.ORDER_STATUS_SCRIPT_SUPPLIED, label: 'Script supplied' },
        { value: optionValues.ORDER_STATUS_WITHOUT_SCRIPT, label: 'Release order without script' }
    ],

    PRODUCT_STATUS_OPTIONS: [
        { value: optionValues.PRODUCT_STATUS_ACTIVE, label: 'Active' },
        { value: optionValues.PRODUCT_STATUS_DRAFT, label: 'Draft' },
        { value: optionValues.PRODUCT_STATUS_DISCONTINUED, label: 'Discontinued' },
        { value: optionValues.PRODUCT_STATUS_INACTIVE, label: 'Inactive' },
    ],

    INTERVAL_OPTIONS: [
        { value: optionValues.INTERVAL_WEEK, label: 'Weeks' },
        { value: optionValues.INTERVAL_MONTH, label: 'Months' },
    ],

    GENDER_OPTIONS: [
        { value: optionValues.GENDER_MALE, label: 'Male' },
        { value: optionValues.GENDER_FEMALE, label: 'Female' },
        { value: optionValues.GENDER_OTHER, label: 'Other' },
    ],

    SUPPLIER_TYPE_OPTIONS: [
        { value: optionValues.SUPPLIER_TYPE_SUPPLIER, label: 'Supplier' },
        { value: optionValues.SUPPLIER_TYPE_MANUFACTURER, label: 'Manufacturer' },
    ],

    DEACTIVATION_REASON_OPTIONS: [
        { value: optionValues.DEACTIVATION_REASON_DECEASED, label: 'Deceased' },
        { value: optionValues.DEACTIVATION_REASON_ARCHIVED, label: 'Archived' },
        { value: optionValues.DEACTIVATION_REASON_ARCHIVED_NO_LONGER_USING, label: 'Archived - No longer using' },
        { value: optionValues.DEACTIVATION_REASON_ARCHIVED_DUPLICATE, label: 'Archived - Duplicate' },
    ],

    PRODUCT_SETTING_OPTIONS: [
        { value: optionValues.EXCLUDE_FROM_AUTO, label: 'Exclude from auto' },
        { value: optionValues.EXCLUDE_FROM_LETTER, label: 'Exclude from Letter' },
    ],

    PRESCRIPTION_FUNDING_OPTIONS: [
        { id: optionValues.FUNDING_METHOD_PAID, text: 'Customer has paid' },
        { id: optionValues.FUNDING_METHOD_CHILD, text: 'Is under 16 years of age' },
        { id: optionValues.FUNDING_METHOD_EDUCATION, text: 'Is 16, 17 or 18 and in full-time education' },
        { id: optionValues.FUNDING_METHOD_SENIOR, text: 'Is 60 years of age or over' },
        { id: optionValues.FUNDING_METHOD_MATERNITY, text: 'Has a valid maternity exemption certificate' },
        { id: optionValues.FUNDING_METHOD_MEDICAL, text: 'Has a valid medical exemption certificate' },
        { id: optionValues.FUNDING_METHOD_PRE_PAYMENT, text: 'Has a valid prescription pre-payment certificate' },
        { id: optionValues.FUNDING_METHOD_WAR_PENSION, text: 'Has a valid War Pension exemption certificate' },
        { id: optionValues.FUNDING_METHOD_HC, text: 'Is named on a current HC2 charges certificate' },
        { id: optionValues.FUNDING_METHOD_INCOME_SUPPORT, text: 'Gets Income Support or income-related Employment and Support Allowance ' },
        { id: optionValues.FUNDING_METHOD_JOBSEEKER, text: 'Gets Income-based Jobseeker’s Allowance ' },
        { id: optionValues.FUNDING_METHOD_TAX_EXEMTION, text: '*is entitled to, or named on, a valid NHS Tax Credit Exemption Certificate' },
        { id: optionValues.FUNDING_METHOD_PENSION_CREDIT, text: '*has a partner who gets Pension Credit guarantee credit (PCGC)' }
    ],

    REPORT_TYPES: [
        { value: optionValues.REPORT_TYPE_CREATED, label: 'Orders Created (CSV)' },
        { value: optionValues.REPORT_TYPE_SCRIPT_WAITING, label: 'Orders Waiting on Script (CSV)' },
        { value: optionValues.REPORT_TYPE_READY, label: 'Orders Ready to Authorise (CSV)' },
        { value: optionValues.REPORT_TYPE_DISPATCHED, label: 'Orders Dispatched (CSV)' },
        { value: optionValues.REPORT_TYPE_PROCESSED, label: 'Orders Processed (CSV)' },
        { value: optionValues.REPORT_TYPE_NURSE, label: 'Orders Sorted by Nurse (CSV)' },
        { value: optionValues.REPORT_TYPE_CAREHOME, label: 'Orders Sorted by Care Home (CSV)' },
        { value: optionValues.REPORT_TYPE_REFERRER, label: 'Orders Sorted by Referrer (CSV)' },
        { value: optionValues.REPORT_TYPE_SUPPLIER, label: 'Orders Including Supplier/Manufacturer (CSV)' },
        { value: optionValues.REPORT_TYPE_PRODUCT, label: 'Orders Including Product (CSV)' },
        { value: optionValues.REPORT_TYPE_PROCESSED_PRESCRIPTIONS, label: 'Processed Prescriptions (PDF)' },
        { value: optionValues.REPORT_TYPE_AUTO_ORDER_ITEMS_PER_PATIENT, label: 'Auto Order Items Per Patient (PDF)' },
        { value: optionValues.REPORT_TYPE_ALL_AUTO_ORDER_ITEMS, label: 'All Auto Order Items (PDF)' },
        { value: optionValues.REPORT_TYPE_PAYMENT_EXAMPTION_LETTER, label: 'Payment/Exemption Letter' },
    ],
};

export default options;
