import React, { Component, Fragment } from 'react';
import { PropTypes } from 'prop-types';
import {
    Row,
    Col,
    Button,
    InputNumber,
    Icon as AntdIcon,
    Select,
} from 'antd';
import { isEmpty, find } from 'underscore';

const { Option } = Select;

function frequencyOptions() {
    return [
        {
            label: '4 weeks',
            value: 4,
        },
        {
            label: '6 weeks',
            value: 6,
        },
        {
            label: '8 weeks',
            value: 8,
        },
        {
            label: '10 weeks',
            value: 10,
        },
        {
            label: '12 weeks',
            value: 12,
        },
        {
            label: '16 weeks',
            value: 16,
        },
        {
            label: '20 weeks',
            value: 20,
        },
        {
            label: '24 weeks',
            value: 24,
        },
        {
            label: '52 weeks',
            value: 52,
        },
    ];
}

class OrderSummaryAndAutoOrderSettingsSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    getQuantity({ id }) {
        const { added, } = this.props;
        const addedProduct = find(added, item => id === item.id);

        return addedProduct.quantity;
    }

    _renderProduct({ item }) {
        const {
            readOnly,
            added,
            callbacks: {
                updateProduct,
                setRepeatOrder
            }
        } = this.props;

        const addedItem = added.find(value => value.id === item.id);

        return (
            <Row key={item.key} type="flex" justify={'space-between'}>
                <Col span={2}>
                    <span className="table-cell justify-content-center">#{item.sku}</span>
                </Col>
                <Col span={7}>
                    <span className="table-cell justify-content-center">{item.title}</span>
                </Col>
                <Col span={2}>
                    <span className="table-cell justify-content-center">{item.requires_prescription ? 'Yes' : 'No'}</span>
                </Col>
                <Col span={3}>
                    <span className="table-cell justify-content-center">
                        <InputNumber
                            className="form__inputnumber"
                            disabled={readOnly}
                            min={1}
                            max={100}
                            defaultValue={1}
                            value={this.getQuantity({ id: item.id })}
                            onChange={(value) => { updateProduct(item.id, value); }} />
                    </span>
                </Col>
                {!readOnly ?
                    <Fragment>
                        <Col span={2}>
                            <div className="flex-center-middle" style={{ height: '100%', }}>
                                <Button
                                    onClick={() => { updateProduct(item.id, 0); }}
                                    type="default"
                                    className="btn-empty ant-btn-xs btn-action-close">
                                    <AntdIcon type={'close-circle'} style={{ color: '#f03f56', fontSize: '160%' }} />
                                </Button>
                            </div>
                        </Col>
                        <Col span={2}>
                            <div className="flex-center-middle" style={{ height: '100%', }}>
                                <Button
                                    onClick={() => { setRepeatOrder({ productId: item.id, input: { action: 'toggle' } }); }}
                                    type="default"
                                    className="btn-empty ant-btn-xs btn-action-close">
                                    <AntdIcon type={(!addedItem.orderItemRepeatInstruction) ? 'undo' : 'stop'} style={{ color: (!addedItem.orderItemRepeatInstruction) ? '#149566' : '#f03f56', fontSize: '160%' }} />
                                </Button>
                            </div>
                        </Col>
                        {(() => {
                            const hasAnyRepeatItems = !added.every(product => !product.orderItemRepeatInstruction);
                            if (addedItem.orderItemRepeatInstruction) {
                                return (
                                    <Fragment>
                                        <Col span={4}>
                                            <div className="flex-center-middle" style={{ height: '100%', }}>
                                                <Select
                                                    style={{ width: '100%' }}
                                                    value={
                                                        addedItem.orderItemRepeatInstruction &&
                                                    addedItem.orderItemRepeatInstruction.interval &&
                                                    parseInt(addedItem.orderItemRepeatInstruction.interval, 10)
                                                    }
                                                    placeholder={'Pick'}
                                                    onChange={value => setRepeatOrder({
                                                        productId: item.id,
                                                        input: {
                                                            action: 'setInterval',
                                                            value,
                                                        }
                                                    })}>
                                                    {frequencyOptions().map(option => (
                                                        <Option key={option.value} value={option.value}>{option.label}</Option>
                                                    ))}
                                                </Select>
                                            </div>
                                        </Col>
                                    </Fragment>
                                );
                            } else if (hasAnyRepeatItems) {
                                return (
                                    <Col span={4}>
                                        <div className="flex-center-middle" style={{ height: '100%' }} />
                                    </Col>
                                );
                            }
                            return null;
                        })()}
                    </Fragment>
                    : false}
            </Row>);
    }

    render() {
        const {
            readOnly,
            added,
            addedProducts,
        } = this.props;

        return (
            <div className="section__container">
                <div className="section__header">
                    <h3 className="h-4">Order summary and auto-order settings</h3>
                </div>
                <div className="section-white small-padding">
                    {!isEmpty(addedProducts) ?
                        <div className="light-table">
                            <div className="light-table__header">
                                <Row type="flex" justify={'space-between'}>
                                    <Col span={2}>
                                        <span className="table-cell justify-content-center">Code</span>
                                    </Col>
                                    <Col span={7}>
                                        <span className="table-cell justify-content-center">Name</span>
                                    </Col>
                                    <Col span={2}>
                                        <span className="table-cell justify-content-center">Prescription Required</span>
                                    </Col>
                                    <Col span={3}>
                                        <span className="table-cell justify-content-center">Quantity</span>
                                    </Col>
                                    {!readOnly ?
                                        <Fragment>
                                            <Col span={2}>
                                                <div className="table-cell justify-content-center">Remove</div>
                                            </Col>
                                            <Col span={2}>
                                                <div className="table-cell justify-content-center">Repeat</div>
                                            </Col>
                                            {!added.every(product => !product.orderItemRepeatInstruction) ? (
                                                <Fragment>
                                                    <Col span={4}>
                                                        <div className="table-cell justify-content-center">Frequency</div>
                                                    </Col>
                                                </Fragment>
                                            ) : null}
                                        </Fragment>
                                        : false}
                                </Row>
                            </div>
                            {addedProducts.map(item => this._renderProduct({ item }))}
                        </div>
                        : <div className="no-data text-center">No Selected Products</div>}
                </div>
            </div>
        );
    }
}

OrderSummaryAndAutoOrderSettingsSection.defaultProps = {
    readOnly: false,
};

OrderSummaryAndAutoOrderSettingsSection.propTypes = {
    readOnly: PropTypes.bool,
    added: PropTypes.array.isRequired,
    addedProducts: PropTypes.array.isRequired,
    callbacks: PropTypes.object.isRequired,
};

export default OrderSummaryAndAutoOrderSettingsSection;
