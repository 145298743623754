/* eslint-disable react/prop-types */
/* eslint-disable no-trailing-spaces */
import React from 'react';
import Layout from '../../components/layout/Layout';
import UpdateCustomer from '../../components/forms/UpdateCustomer';
import SearchDoctorsPractice from '../../components/modals/SearchDoctorsPractice';
import CancelAutoOrder from '../../components/modals/CancelAutoOrder';
import UpdateAutoOrder from '../../components/modals/UpdateAutoOrder';


export default function (props) {
    const {
        actions,
        params,
        name,
        toolbar,
        modalAssignVisible, 
        modalCancelAutoOrderVisible, 
        modalCancelAutoOrderId, 
        modalUpdateAutoOrderVisible, 
        modalUpdateAutoOrderId, 
        modalUpdateAutoOrderInterval, 
        modalUpdateAutoOrderIntervalType 
    } = props;

    const customerId = parseInt(params.id, 10) || 0;

    // Create breadcrumbs array for the layout
    const breadcrumbs = [
        { caption: 'Customers', href: '/customers' },
        { caption: name },
    ];

    return (
        <div>
            <Layout
                title={name}
                toolbar={toolbar}
                breadcrumbs={breadcrumbs}>
                <UpdateCustomer
                    customerId={customerId}
                    handleOpenModal={actions.handleOpenModal}
                    handleTabChange={actions.handleTabChange}
                    setPageName={actions.setPageName}
                    params={params}
                    modalAssignVisible={modalAssignVisible}
                    modalCancelAutoOrderVisible={modalCancelAutoOrderVisible}
                    modalCancelAutoOrderId={modalCancelAutoOrderId}
                    modalUpdateAutoOrderVisible={modalUpdateAutoOrderVisible}
                    modalUpdateAutoOrderId={modalUpdateAutoOrderId}
                    modalUpdateAutoOrderInterval={modalUpdateAutoOrderInterval}
                    modalUpdateAutoOrderIntervalType={modalUpdateAutoOrderIntervalType} />

                <SearchDoctorsPractice
                    customerId={customerId}
                    visible={modalAssignVisible}
                    changeVisible={actions.setModalAssignVisible} />
                <CancelAutoOrder
                    id={modalCancelAutoOrderId}
                    customerId={customerId}
                    visible={modalCancelAutoOrderVisible}
                    changeVisible={actions.setModalCancelAutoOrderVisible} />
                <UpdateAutoOrder
                    id={modalUpdateAutoOrderId}
                    customerId={customerId}
                    interval={modalUpdateAutoOrderInterval}
                    intervalType={modalUpdateAutoOrderIntervalType}
                    visible={modalUpdateAutoOrderVisible}
                    changeVisible={actions.setModalUpdateAutoOrderVisible} />
            </Layout>
        </div>
    );
}
