import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { notification } from 'antd';

import DOM from './customer';

class CustomerPage extends Component {
    // Define required fields as a class property for easier maintenance
    requiredFields = [
        // Customer details
        'first_name',
        'last_name',
        // Home address
        'home_address.address_line_1',
        'home_address.city_region',
        'home_address.postcode',
        'home_address.telephone'
    ];

    constructor(props) {
        super(props);
        this.view = DOM;

        const initialToolbar = this.getToolbar(props.params.id, 1);

        this.state = {
            modalAssignVisible: false,

            modalCancelAutoOrderVisible: false,
            modalCancelAutoOrderId: 0,

            modalUpdateAutoOrderVisible: false,
            modalUpdateAutoOrderId: 0,
            modalUpdateAutoOrderInterval: 0,
            modalUpdateAutoOrderIntervalType: 'Day',

            name: parseInt(props.params.id, 10) ? `#${props.params.id}` : 'New Customer',
            // toolbar: this.getToolbar(props.params.id),
            toolbar: initialToolbar,
        };
    }

    componentWillReceiveProps(nextProps) {
        // Only update toolbar if params.id has changed
        if (this.props.params.id !== nextProps.params.id) {
            // this.setState({ toolbar: this.getToolbar(parseInt(nextProps.params.id, 10)) });
            this.setState({ toolbar: this.getToolbar(parseInt(nextProps.params.id, 10), 1) });
        }
    }

    // Handle get toolbar based on tab
    getToolbar = (id, key) => {
        // const tab = key || 1;
        const tab = key ? Number(key) : 1;
        const toolbar = [
            {
                id: 'actionNewOrder',
                caption: 'New order',
                icon: 'new-order',
                href: `/order/0/customer/${id}`,
                target: 'blank'
            },
        ];

        if (tab === 4) {
            toolbar.push({
                id: 'actionAssignDoctorPractice',
                caption: 'Assign doctor or practice',
                icon: 'new-order',
                onClick: () => this.setModalAssignVisible(true)
            });
        }

        if (![4, 5].includes(tab)) {
            toolbar.push({
                id: 'actionSave',
                caption: 'Save',
                icon: 'save',
                onClick: this.handleUpdateCustomerSubmit
            });
        }

        return toolbar;
    };

    // Handle set page name
    setPageName = name => this.setState({ name });

    // Handle open doctor practice modal
    setModalAssignVisible = modalAssignVisible => this.setState({ modalAssignVisible });

    // Handle open cancel auto order modal
    setModalCancelAutoOrderVisible = modalCancelAutoOrderVisible => this.setState({ modalCancelAutoOrderVisible });

    // Handle open update auto order modal
    setModalUpdateAutoOrderVisible = modalUpdateAutoOrderVisible => this.setState({ modalUpdateAutoOrderVisible });

    // Handle open modal
    handleOpenModal = (modal, id, interval, type) => {
        const newState = {};
        newState[`${modal}Visible`] = true;
        newState[`${modal}Id`] = id;
        if (interval && type) {
            newState[`${modal}Interval`] = interval;
            newState[`${modal}IntervalType`] = type;
        }
        this.setState(newState);
    }

    // Handle submit form
    handleUpdateCustomerSubmit = () => {
        const form = this.props.form;
        // If form is not available (not yet loaded), simply submit
        if (!form || !form.values) {
            this.props.actions.submit('update-customer');
            return;
        }

        // Check if form has any unfilled required fields
        const missingFields = [];

        // Helper function to get value from potentially nested field path
        const getFieldValue = (obj, path) => {
            if (!obj) return undefined;

            const parts = path.split('.');
            let current = obj;

            for (const part of parts) {
                if (current === null || current === undefined) {
                    return undefined;
                }
                current = current[part];
            }
            return current;
        };

        this.requiredFields.forEach(field => {
            const value = getFieldValue(form.values, field);

            // Check if the value is empty (undefined, null, or empty string)
            if (value === undefined || value === null || value === '') {
                // Format field name for display (e.g., "home_address.address_line_1" -> "Home Address Line 1")
                const formattedField = field
                    .replace(/\./g, ' ') // Replace all dots with spaces
                    .split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');

                missingFields.push(formattedField);
            }
        });

        // If there are missing required fields, show notification and don't submit
        if (missingFields.length > 0) {
            notification.error({
                message: 'Required Fields Missing',
                description: `Please fill in the following required fields: ${missingFields.join(', ')}`,
                duration: 5
            });
            // return;
        }

        // Added this check to make users aware the reason why the form is not submitting
        this.props.actions.submit('update-customer');
    };

    // Handle tab change
    // handleTabChange = key => this.setState({ toolbar: this.getToolbar(this.props.params.id, parseInt(key, 10)) });
    handleTabChange = (key) => {
        const numericKey = typeof key === 'number' ? key : Number(key);
        const newToolbar = this.getToolbar(this.props.params.id, numericKey);

        this.setState({
            toolbar: newToolbar
        });
    };

    render() {
        // return this.view();

        // Add a safety check
        if (typeof this.view !== 'function') {
            return <div>Error loading page</div>;
        }

        // Pass params and methods to the view function
        const viewProps = {
            ...this.state,
            params: this.props.params,
            actions: {
                ...this.props.actions,
                handleOpenModal: this.handleOpenModal,
                handleTabChange: this.handleTabChange,
                setPageName: this.setPageName,
                setModalAssignVisible: this.setModalAssignVisible,
                setModalCancelAutoOrderVisible: this.setModalCancelAutoOrderVisible,
                setModalUpdateAutoOrderVisible: this.setModalUpdateAutoOrderVisible
            }
        };
        return this.view(viewProps);
    }
}

CustomerPage.propTypes = {
    actions: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        // ..ownProps
        ...ownProps,
        form: state.form && state.form['update-customer']
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ submit }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPage);
