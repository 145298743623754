import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, stopSubmit } from 'redux-form';
import { isUndefined, isEmpty } from 'underscore';
import { notification } from 'antd';
import { browserHistory } from 'react-router';

import * as customerActions from '../../../core/customer/customerActions';
import * as nurseActions from '../../../core/nurse/nurseActions';
import * as productActions from '../../../core/product/productActions';
import * as referrerActions from '../../../core/referrer/referrerActions';

import { validateCustomer } from '../../../core/utils/validators';

import DOM from './updateCustomer';
import { mapTitleToValue } from '../../../core/utils/helpers';

class UpdateCustomerForm extends Component {
    constructor(props) {
        super(props);
        this.handleSetPageName(props);
        this.view = DOM;
        this.state = {
            activeTab: props.customerId === 0 ? '2' : '1', // Set active tab to '2' if customerId is 0
        };
    }

    componentDidMount() {
        const { actions, customerId } = this.props;
        // const browserHistoryState = browserHistory.getCurrentLocation().state;

        // if (browserHistoryState && browserHistoryState.activeTab) {
        //     this.setState({
        //         activeTab: browserHistoryState.activeTab
        // else if (browserHistoryState && browserHistoryState.from === 'prescription-matcher') {
        //     this.setState({
        //         activeTab: '7' // Set active tab to '7'
        //     });

        const defaultTab = customerId === 0 ? '2' : '1';
        this.state.activeTab = defaultTab;

        // Force update the browser history
        const currentLocation = browserHistory.getCurrentLocation();
        browserHistory.replace({
            pathname: currentLocation.pathname,
            query: currentLocation.query,
            state: {
                activeTab: defaultTab
            }
        });

        if (typeof this.props.handleTabChange === 'function') {
            const numericTab = parseInt(defaultTab, 10);
            this.props.handleTabChange(numericTab);
        }
        // Load data
        actions.getCustomerRequest({ id: customerId });
        actions.getCustomerDoctorRequest({ id: customerId });
        actions.getCustomerPracticeRequest({ id: customerId });
        actions.getNursesRequest({ showAll: true });
        actions.getProductsRequest();
        actions.getReferrersRequest({ showAll: true });
    }

    componentWillReceiveProps(nextProps) {
        this.handleSetPageName(nextProps);
    }

    // Handle tab change
    handleTabChange = (tab) => {
        // Convert tab to string for internal state
        const tabString = tab.toString();
        this.setState({ activeTab: tabString });

        // Update the browser history state to reflect the new tab
        const currentLocation = browserHistory.getCurrentLocation();
        browserHistory.replace({
            pathname: currentLocation.pathname,
            query: currentLocation.query,
            state: {
                activeTab: tabString
            }
        });

        // Call the parent's handleTabChange if it exists
        if (typeof this.props.handleTabChange === 'function') {
            const numericTab = typeof tab === 'number' ? tab : parseInt(tab, 10);
            this.props.handleTabChange(numericTab);
        }
    }

    // Handle set page name
    handleSetPageName(props) {
        const { setPageName, initialValues, customerId, isFetching } = props;
        if (!isFetching) {
            if (!isUndefined(initialValues.first_name)) {
                setPageName(`#${initialValues.id} - ${initialValues.first_name} ${initialValues.last_name}`);
            } else if (customerId === 0) {
                setPageName('New Customer');
            }
        }
    }

    // Handle submit form
    handleUpdateCustomerSubmit = (values) => {
        const { actions, customerId } = this.props;
        const errors = validateCustomer(values);
        if (!isEmpty(errors)) {
            // Format errors for better readability
            const errorMessages = Object.values(errors).join(', ');

            actions.stopSubmit('update-customer', errors);
            notification.error({
                message: 'Error(s) detected',
                description: errorMessages, // Display simplified error messages
            });
            return; // Stop submission if there are errors
        }

        const formValues = {
            id: customerId,
            ...values,
        };
        if (formValues.is_active === 1) {
            // remove deactivation reason
            delete formValues.deactivation_reason;
        }
        actions.updateCustomerRequest(formValues);
    };

    render() {
        return this.view();
    }
}

UpdateCustomerForm.propTypes = {
    actions: PropTypes.object.isRequired,
    customerId: PropTypes.number.isRequired,
    params: PropTypes.object.isRequired,
    handleTabChange: PropTypes.func,
    setPageName: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    isFetching: PropTypes.bool,
};

UpdateCustomerForm.defaultProps = {
    handleTabChange: () => {}, // Provide a default empty function
    initialValues: {},
    isFetching: false,
};

const selector = formValueSelector('update-customer');
const UpdateCustomer = reduxForm({
    form: 'update-customer',
    enableReinitialize: true,
    validate: validateCustomer,
})(UpdateCustomerForm);

function mapStateToProps(state, ownProps) {
    const differentCare = selector(state, 'is_care_of_address_different') ? 1 : 0;
    let customerStatus = selector(state, 'is_active');
    if (!customerStatus && customerStatus !== 0) {
        customerStatus = 1;
    }

    const customer = state.customer.customer;
    if (customer && customer.title !== undefined && customer.title !== null) {
        // customer.title = typeof customer.title === 'string' ? parseInt(customer.title, 10) : customer.title;
        customer.title = mapTitleToValue(customer.title);
    }

    return {
        ...ownProps,
        differentCare,
        customerStatus,
        // initialValues: state.customer.customer,
        // customer: state.customer.customer,
        initialValues: customer,
        customer,
        customerSearchResults: state.customer.customerSearchResults,
        doctor: state.customer.doctor,
        practice: state.customer.practice,
        isFetching: state.customer.isFetching,
        nurses: state.nurse.nurses.rows,
        products: state.product.products.rows,
        referrers: state.referrer.referrers.rows,
        title: state.customer.customer?.id ? `Customer: ${state.customer.customer.first_name} ${state.customer.customer.last_name}` : 'New Customer',
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...customerActions,
                ...nurseActions,
                ...productActions,
                ...referrerActions,
                stopSubmit,
            },
            dispatch
        )
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCustomer);
