import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { isUndefined } from 'underscore';
import { reduxForm } from 'redux-form';

import * as productActions from '../../../core/product/productActions';
import * as supplierActions from '../../../core/supplier/supplierActions';

import DOM from './updateSupplier';

class UpdateSupplierForm extends Component {
    constructor(props) {
        super(props);
        this.handleSetPageName(props);
        this.view = DOM;
    }

    componentWillMount() {
        const { actions, supplierId } = this.props;
        actions.getSupplierRequest(supplierId);
        actions.getProductCategoriesRequest();
    }

    componentWillReceiveProps(nextProps) {
        this.handleSetPageName(nextProps);
    }

    // Handle set page name
    handleSetPageName(props) {
        const { setPageName, initialValues, supplierId, isFetching } = props;
        if (!isFetching) {
            if (!isUndefined(initialValues.title)) {
                setPageName(`#${initialValues.id} - ${initialValues.title}`);
            } else if (supplierId === 0) {
                setPageName('New Supplier/Manufacturer');
            }
        }
    }

    // Handle submit form
    handleUpdateSupplierSubmit = (values) => {
        const { actions, supplierId } = this.props;
        const formValues = {
            ...values,
            id: supplierId,
            is_supplier: values.type.includes(1) ? 1 : 0,
            is_manufacturer: values.type.includes(2) ? 1 : 0,
        };
        actions.updateSupplierRequest(formValues);
    };

    render() {
        return this.view();
    }
}

UpdateSupplierForm.defaultProps = {
    setPageName: () => {}
};

UpdateSupplierForm.propTypes = {
    actions: PropTypes.object.isRequired,
    supplierId: PropTypes.number.isRequired,
};

const UpdateSupplier = reduxForm({
    form: 'update-supplier',
    enableReinitialize: true,
})(UpdateSupplierForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        initialValues: state.supplier.supplier,
        isFetching: state.supplier.isFetching,
        productCategories: state.product.productCategories,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...productActions,
            ...supplierActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSupplier);
