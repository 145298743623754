import React, { Component, } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Button, Input, notification, Spin } from 'antd';
import Select from 'react-select';
import { browserHistory } from 'react-router';
import { isEqual } from 'underscore';

import styles from './styles.js';

import { debounce } from '../../../../../../core/utils/helpers';

import searchIcon from '../../../../../../assets/images/search-icon.png';
import doctorIcon from '../../../../../../assets/images/doctor-icon.png';
import notesIcon from '../../../../../../assets/images/notes-icon.png';
import profileIcon from '../../../../../../assets/images/profile-icon.png';
import editIcon from '../../../../../../assets/images/edit-icon.png';
import saveIcon from '../../../../../../assets/images/save-icon.png';
import line from '../../../../../../assets/images/line.png';

import { generateClientDetails, generateNoteDetails, generateDoctorDetails, customerActiveOptions, generatePracticeDetails } from './dataGenerators';

import * as customerActions from '../../../../../../core/customer/customerActions';

class SideBar extends Component {
    constructor(props) {
        super(props);
        const { customer, doctor, practice } = this.props;

        this.state = {
            activeForm: -1,
            clientForm: generateClientDetails({ customer }),
            noteForm: generateNoteDetails({ customer }),
            doctorForm: generateDoctorDetails({ doctor, practice }),
            practiceForm: generatePracticeDetails({ practice }),
            customerSearchResults: [],
            searchInput: '',
            selectedInput: null,
            filters: {},
            errors: {},
        };
    }

    componentWillReceiveProps(nextProps) {
        const { customerSearchResults } = this.state;
        const { customer, practice, doctor } = this.props;

        // Update the state with new redux state values
        if (!isEqual(nextProps.customer, customer)) {
            this.setState({ clientForm: generateClientDetails({ customer: nextProps.customer }), noteForm: generateNoteDetails({ customer: nextProps.customer }) });
        }
        if (!isEqual(nextProps.practice, practice) || !isEqual(nextProps.doctor, doctor)) {
            this.setState({ doctorForm: generateDoctorDetails({ doctor: nextProps.doctor, practice: nextProps.practice }) });
        }
        if (!isEqual(nextProps.practice, practice)) {
            this.setState({ practiceForm: generatePracticeDetails({ practice: nextProps.practice }) });
        }
        if (!isEqual(nextProps.customerSearchResults, customerSearchResults)) {
            this.setState({ customerSearchResults: nextProps.customerSearchResults });
        }
    }

    handleSetSearchInput({ value, action }) {
        if (action.action === 'input-change') {
            const { filters } = this.state;
            const { activeStatus } = filters;

            const actions = this.props.actions;

            // Set state
            const stringValue = value.toString();
            this.setState({
                searchInput: stringValue,
            });

            debounce(
                actions.searchCustomersByAttributesRequest({
                    searchInput: stringValue,
                    activeStatus: activeStatus && activeStatus.value,
                })
                , 50);
        } else {
            this.setState({ searchInput: action.prevInputValue, });
        }
    }

    handleSetSelectedInput({ value, action }) {
        if (action.action === 'select-option') {
            this.setState({
                selectedInput: value,
            });
        }
    }

    handleOnClick() {
        const actions = this.props.actions;
        if (this.state.selectedInput) {
            const customerId = this.state.selectedInput.id;
            actions.getCustomerRequest({ id: customerId });
            actions.getCustomerDoctorRequest({ id: customerId });
            actions.getCustomerPracticeRequest({ id: customerId });
            browserHistory.push(`/customer/${customerId}`);
            this.setState({
                selectedInput: null,
                customerSearchResults: [],
            });
        } else {
            notification.error({ message: 'Error: You must select a customer before clicking "Go".' });
        }
    }

    validateForm(formData) {
        const errors = {};
        formData.forEach((field) => {
            if (field.required && !field.formValue) {
                errors[field.formName] = `${field.formLabel} is required`;
            }
        });
        return errors;
    }

    handleEditClick(formNumber) {
        //     this.setState({
        //         activeForm: formNumber,
        //         errors: {}, // Clear errors when entering edit mode
        //     });

        if (formNumber === 4) {
            if (typeof this.props.handleTabChange === 'function') {
                this.props.handleTabChange(4);
            }
        } else if (formNumber === 2) {
            if (typeof this.props.handleTabChange === 'function') {
                this.props.handleTabChange(8);
            }
        } else if (formNumber === 1) {
            if (typeof this.props.handleTabChange === 'function') {
                this.props.handleTabChange(2);
            }
        }
    }

    // This functionality is not needed anymore but has been left in place for future reference, see handleEditClick above for new implementation
    handleSaveClick({ formNumber }) {
        const actions = this.props.actions;
        // const { clientForm, noteForm, doctorForm, practiceForm } = this.state;
        const { clientForm, noteForm, practiceForm } = this.state;
        const { customer, doctor, practice } = this.props;

        let formToValidate;
        switch (formNumber) {
        case 1:
            formToValidate = clientForm.data;
            break;
        case 2:
            formToValidate = noteForm.data;
            break;
        // case 3:
        //     formToValidate = doctorForm.data;
        //     break;
        case 3:
            formToValidate = practiceForm.data;
            break;
        default:
            return;
        }

        const errors = this.validateForm(formToValidate);
        if (Object.keys(errors).length > 0) {
            this.setState({ errors });
            notification.error({
                message: 'Validation Error',
                description: Object.values(errors).join(', '), // Show all errors in toast
            });
            return; // Stop execution if there are errors
        }

        // Submit form if no errors
        switch (formNumber) {
        case 1: {
            const formData = {
                ...clientForm.data,
                customerId: customer.id,
                formType: 0,
            };

            if (doctor && doctor.id) {
                formData.doctorId = doctor.id;
            }

            if (practice && practice.id) {
                formData.practiceId = practice.id;
            }

            actions.submitSummaryFormRequest(formData);
            break;
        }

        case 2: {
            const formData = {
                ...noteForm.data,
                customerId: customer.id,
                formType: 1,
            };

            if (doctor && doctor.id) {
                formData.doctorId = doctor.id;
            }

            if (practice && practice.id) {
                formData.practiceId = practice.id;
            }

            actions.submitSummaryFormRequest(formData);
            break;
        }

        case 3: {
            const formData = {
                ...practiceForm.data,
                customerId: customer.id,
                formType: 2,
            };

            if (practice && practice.id) {
                formData.practiceId = practice.id;
            }

            actions.submitSummaryFormRequest(formData);
            break;
        }
        default:
            break;
        }

        this.setState({
            activeForm: -1, // Exit edit mode after saving
            errors: {},
        });
    }

    handleOnChange({ event, formName, formNumber }) {
        let relevantIndex = null;
        switch (formNumber) {
        case 1:
            const clientFormBuffer = this.state.clientForm;
            relevantIndex = clientFormBuffer.data.findIndex(element => element.formName === formName);
            clientFormBuffer.data[relevantIndex].formValue = event.target.value;
            this.setState({
                clientForm: { ...clientFormBuffer },
            });
            break;
        case 2:
            const notesFormStateBuffer = this.state.noteForm;
            relevantIndex = notesFormStateBuffer.data.findIndex(element => element.formName === formName);
            notesFormStateBuffer.data[relevantIndex].formValue = event.target.value;
            this.setState({
                notesFormState: { ...notesFormStateBuffer },
            });
            break;
        // case 3:
        //     const doctorFormBuffer = this.state.doctorForm;
        //     relevantIndex = doctorFormBuffer.data.findIndex(element => element.formName === formName);
        //     doctorFormBuffer.data[relevantIndex].formValue = event.target.value;
        //     this.setState({
        //         doctorForm: { ...doctorFormBuffer },
        //     });
        //     break;
        default:
            break;
        }
    }

    handleOnChangeStatusInput({ option, action }) {
        const { actions } = this.props;
        const { searchInput } = this.state;
        const { filters } = this.state;
        this.setState({ filters: { ...filters, activeStatus: option, } });
        actions.searchCustomersByAttributesRequest({
            searchInput,
            activeStatus: option && option.value,
        });
    }

    _renderSearchBar() {
        const { filters } = this.state;
        const customerSearchResults = (typeof this.state.customerSearchResults !== 'string') ? this.state.customerSearchResults : [];

        const options = customerSearchResults.map((value, index) => (
            {
                label: `${value.full_name} (ID - ${value.id})`,
                id: value.id,
                value: this.state.searchInput,
                index,
            }
        ));

        return (
            <div style={styles.searchBarContainer}>
                <p style={styles.searchBarTitle}>Quickfind</p>
                <Select
                    className={'customer_search_input'}
                    styles={{
                        ...styles.selectInput,
                        valueContainer: (provided, state) => ({
                            ...provided,
                            height: '25px',
                            padding: '0px 8px',
                        }),
                        control: (provided, state) => ({
                            ...provided,
                            background: '#fff',
                            borderColor: '#9e9e9e',
                            minHeight: undefined,
                            height: '25px',
                            boxShadow: state.isFocused ? null : null,
                            '&:hover': {
                                borderColor: '#149566',
                                color: '#149566'
                            }
                        }),
                    }}
                    onFocus={() => this.setState({ searchInput: '', selectedInput: null })}
                    value={this.state.selectedInput}
                    inputValue={!this.state.selectedInput && this.state.searchInput}
                    placeholder={'Search'}
                    formatOptionLabel={({ value, label, index }) => (
                        <p style={{ margin: 0, fontWeight: (index === 0) ? 'bold' : 'normal' }}>{label}</p>
                    )}
                    onInputChange={(value, action) => this.handleSetSearchInput({ value, action })}
                    onChange={(value, action) => this.handleSetSelectedInput({ value, action })}
                    options={options} />
                <Select
                    styles={{
                        ...styles.selectInput,
                        valueContainer: (provided, state) => ({
                            ...provided,
                            height: '25px',
                            padding: '0px 8px',
                        }),
                        control: (provided, state) => ({
                            ...provided,
                            background: '#fff',
                            borderColor: '#9e9e9e',
                            minHeight: undefined,
                            height: '25px',
                            boxShadow: state.isFocused ? null : null,
                            '&:hover': {
                                borderColor: '#149566',
                                color: '#149566'
                            },
                            minWidth: '120px',
                        }),
                    }}
                    isSearchable={false}
                    placeholder={'Status'}
                    value={filters.status}
                    onChange={(option, action) => this.handleOnChangeStatusInput({ option, action })}
                    options={customerActiveOptions().map(option => ({
                        label: option.label,
                        value: option.value,
                    }))} />
                <Button
                    style={styles.searchBarButton}
                    onClick={() => this.handleOnClick()}
                    success="true">Go
                </Button>
            </div >
        );
    }

    _renderFormSection(form) {
        const { customer } = this.props;
        const { is_active } = customer;
        const { formNumber } = form;

        // Determine title and image based on formNumber
        let title = '';
        let image = '';
        switch (formNumber) {
        case 1:
            title = 'Client Details';
            image = profileIcon;
            break;
        case 2:
            title = 'Notes';
            image = notesIcon;
            break;
        case 3:
            title = 'Practice Details';
            image = doctorIcon;
            break;
        default:
            title = 'Practice Details';
            image = doctorIcon;
            break;
        }

        return (
            <div style={styles.formSectionContainer}>
                <div style={styles.formSectionHeader}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                        <img
                            style={{
                                width: 15,
                                height: 15,
                                marginRight: 5,
                                marginLeft: 5,
                            }}
                            src={image}
                            alt="" />
                        <p style={styles.formSectionTitle}>{title}</p>
                        {formNumber === 1 && (
                            <p style={{ ...styles.formSectionStatusText, backgroundColor: (is_active === 1) ? 'green' : 'orange', }}>
                                {(is_active === 1) ? 'Active' : 'Inactive'}
                            </p>
                        )}
                    </div>
                    <div
                        onClick={() => (this.state.activeForm !== formNumber ? this.handleEditClick(formNumber) : this.handleSaveClick({ formNumber }))}
                        style={styles.buttonWrapper}>
                        <img
                            style={{
                                width: 15,
                                height: 15,
                                marginRight: 5,
                                marginLeft: 5,
                            }}
                            src={this.state.activeForm !== formNumber ? editIcon : saveIcon}
                            alt="" />
                    </div>
                </div>
                {form.data.map(value => (
                    <div key={`form_${formNumber}_field_${value.formName}`} style={{ display: 'flex', justifyContent: 'space-between', }}>
                        <p style={styles.formFieldLabel}>{value.formLabel}</p>
                        <Input
                            placeholder=""
                            onChange={event => this.handleOnChange({ event, formName: value.formName, formNumber })}
                            disabled={this.state.activeForm !== formNumber}
                            value={value.formValue}
                            size="small"
                            style={styles.formFieldInput} />
                    </div>
                ))}
            </div>
        );
    }

    render() {
        const { isGettingCustomer, isSubmittingSummaryForm } = this.props;

        return (
            <div style={styles.mainContainer} className="customer_summary_sidebar">
                <Spin spinning={isGettingCustomer || isSubmittingSummaryForm}>
                    {this._renderSearchBar()}
                    {this._renderFormSection(this.state.clientForm)}
                    <img src={line} style={{ width: '100%' }} alt="Divider" />
                    {this._renderFormSection(this.state.noteForm)}
                    <img src={line} style={{ width: '100%' }} alt="Divider" />
                    {/* {this._renderFormSection(this.state.doctorForm)} */}
                    {this._renderFormSection(this.state.practiceForm)}
                </Spin>
            </div>
        );
    }
}

SideBar.defaultProps = {
    customer: {},
    doctor: {},
    practice: {},
    actions: {},
    customerSearchResults: [],
    isGettingCustomer: false,
    isSubmittingSummaryForm: false,
    handleTabChange: null,
};

SideBar.propTypes = {
    customer: PropTypes.object,
    doctor: PropTypes.object,
    practice: PropTypes.object,
    actions: PropTypes.object,
    customerSearchResults: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    isGettingCustomer: PropTypes.bool,
    isSubmittingSummaryForm: PropTypes.bool,
    handleTabChange: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        customer: state.customer.customer,
        customerSearchResults: state.customer.customerSearchResults,
        doctor: state.customer.doctor,
        isGettingCustomer: state.customer.isGettingCustomer,
        isSubmittingSummaryForm: state.customer.isSubmittingSummaryForm,
        practice: state.customer.practice,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...customerActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
