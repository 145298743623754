import React from 'react';

import Gridview from '../../../elements/Gridview';
import { mapToOptions, displayId, displayName } from '../../../core/utils/helpers';

const { GENDER_OPTIONS } = require('../../../core/utils/options').default;

export default function () {
    const { customers, renderButtons, total, page, nurses, referrers, isFetching } = this.props;
    const nurseOptions = mapToOptions(nurses, 'id', displayName);
    const referrerOptions = mapToOptions(referrers, 'id', displayName);

    // Gridview config
    const config = [
        { dataIndex: 'reference', title: 'ID', search: true, type: 'text', width: 100, render: displayId },
        { dataIndex: 'customer_first_name', title: 'Name', className: 'text-semibold', render: (value, record) => displayName(record) },
        { dataIndex: 'nhs_no', title: 'NHS Number', search: false, type: 'text', visible: false },
        { dataIndex: 'first_name', title: 'Forenames', search: true, type: 'text', visible: false },
        { dataIndex: 'last_name', title: 'Surnames', search: true, type: 'text', visible: false },
        { dataIndex: 'gender', title: 'Gender', search: false, type: 'radio', options: GENDER_OPTIONS, visible: false },
        { dataIndex: 'dob', title: 'Date of Birth', search: true, type: 'date', visible: false },
        { dataIndex: 'nurse_id', title: 'Nurse', search: true, type: 'select', options: nurseOptions, visible: false },
        { dataIndex: 'referrer_id', title: 'Referrer', search: false, type: 'select', options: referrerOptions, visible: false },
        { dataIndex: 'address_line_2', title: 'Address Line 1' },
        { dataIndex: 'postcode', title: 'Postcode', search: true, type: 'text' },
        { dataIndex: 'care_postcode', title: 'Care of Postcode', search: false, type: 'text', visible: false },
        { dataIndex: 'gp_reference', title: 'GP Ref', search: false, type: 'text', visible: false },
        { dataIndex: 'active_auto', title: 'Has an active auto order', search: true, type: 'checkbox', visible: false },
        { dataIndex: 'buttons', render: renderButtons },
    ];

    return (
        <Gridview
            config={config}
            data={customers}
            handleClearSearch={this.handleClearSearch}
            handleSearch={this.handleSearch}
            loading={isFetching}
            pagination={{
                simple: true,
                onChange: this.handlePagination,
                pageSize: 10,
                total,
                current: page,
                className: 'custom-pagination',
            }}
            searchTitle="Find a Customer"
            title="Search Results" />
    );
}
