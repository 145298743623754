/* global localStorage:true */
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { mapObject, isEmpty, isNull, isUndefined } from 'underscore';
import moment, { isMoment } from 'moment';

const { DB_DATE_FORMAT, DISPLAY_DATE_FORMAT } = require('./values').default;

// ----- General Helpers -----//

/**
 * Get display id with # in front
 * @param  {number} id
 * @return {string}
 */
const displayId = (sku) => {
    if (!sku || typeof sku !== 'string') return '';
    const strippedSku = sku.replace(/<\/?[^>]+(>|$)/g, ''); // Strip HTML tags
    return `#${strippedSku}`;
};

/**
 * Get display name
 * @param  {object} object
 * @param  {string} firstNameProp - (OPT) object prop for first name, default: 'first_name'
 * @param  {string} lastNameProp - (OPT) object prop for last name, default: 'last_name'
 * @return {string}
 */
const displayName = (object, firstNameProp, lastNameProp) => {
    const firstName = object[firstNameProp || 'first_name'] || '';
    const lastName = object[lastNameProp || 'last_name'] || '';
    return `${firstName} ${lastName}`;
};

/**
 * Get display number
 * @param  {integer} number
 * @return {string}
 */
const displayNumber = number => `${number < 10 && number >= 0 ? '0' : ''}${number}`;

/**
 * Get display date
 * @param  {string} value
 * @param  {string} format - (OPT) date format of value, default: 'Y-m-d H:i:s'
 * @return {string}
 */
const displayDate = (value, format) => {
    let date = '';
    if (value && !isNull(value) && !isUndefined(value)) {
        date = moment(value, format || DB_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
    }
    return date;
};

function checkDate(date, type = 'output', format = 'YYYY-MM-DD hh:mm:ss') {
    let dateBuffer = date;
    const inputDateFormats = ['DD/MM/YYYY', 'YYYY-MM-DD', 'YYYY-MM-DD hh:mm:ss'];

    if (typeof dateBuffer !== 'undefined' && dateBuffer) {
        if (!isMoment(dateBuffer)) {
            for (let i = 0; i < inputDateFormats.length; i++) {
                if (moment(dateBuffer, inputDateFormats[i], true).isValid()) {
                    dateBuffer = moment(dateBuffer, inputDateFormats[i]);
                    break;
                }
            }
        }
        if (!isMoment(dateBuffer)) dateBuffer = moment(dateBuffer);
        if (isMoment(dateBuffer)) {
            if (type === 'input') {
                return moment(dateBuffer.format(format), format);
            } else if (type === 'output') {
                return dateBuffer.format(format);
            }
        }
    }

    return null;
}

function debounce(func, wait, immediate) {
    let timeout;

    return function executedFunction() {
        const context = this;
        const args = arguments;

        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        const callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) func.apply(context, args);
    };
}

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

// ----- Array Mapping Helpers -----//

/**
 * Add extra 'key' property to each item in array
 * @param {array} array - array of objects
 * @param {string} key - (optional) name of key to use for 'key' property
 */
const addKeyProp = (array, key) => array.map(item => ({ key: item[key || 'id'], ...item }));

/**
 * Map array of objects to be used for a select component options
 * @param  {array} array - array to map
 * @param  {string} valueKey - (optional) key of property for option value
 * @param  {string|function} labelKey - (optional) key of property for option display label
 * @return {array} - eg. [{ value: 'value1', name: 'name1' }, ...]
 */
const mapToOptions = (array, valueKey, labelKey) => {
    const options = [];
    array.forEach((item) => {
        let label = '';
        if (typeof labelKey === 'function') {
            label = labelKey(item);
        } else {
            label = item[labelKey || 'title'] || '[no-title]';
        }

        options.push({ value: item[valueKey || 'id'], label });
    });
    return options;
};

// ----- Object Mapping Helpers -----//

/**
 * Map specified properties in object to integers
 * @param  {object} object - object to map
 * @param  {[type]} props - properties to turn map integers
 * @return {object}
 */
const mapPropsToInts = (object, props) => mapObject(object, (value, key) => {
    if (props.includes(key) && value && !isNull(value) && !isUndefined(value)) {
        return parseInt(value, 10);
    }
    return value;
});

/**
 * Map specified boolean properties in object to integers (1 or 0)
 * @param  {object} object - object to map
 * @param  {[type]} props - properties to turn map integers
 * @return {object}
 */
const mapPropsBoolToInts = (object, props) => mapObject(object, (value, key) => {
    if (props.includes(key) && !isNull(value) && !isUndefined(value)) {
        return value ? 1 : 0;
    }
    return value;
});

/**
 * Map specified properties in object to diffenrent date formats
 * @param  {object} object - object to map
 * @param  {array} props - properties to map to dates
 * @param  {string} currentFormat - format of date property before map
 * @param  {string} newFormat - format of date property after map
 * @return {object}
 */
const mapPropsToDates = (object, props, currentFormat, newFormat) => mapObject(object, (value, key) => {
    if (props.includes(key) && value && !isNull(value) && !isUndefined(value)) {
        return moment(value, currentFormat).format(newFormat);
    }
    return value;
});

/**
 * Map specified properties in object to date of format 'Y-m-d H:i:s'
 * @param  {object} object - object to map
 * @param  {array} props - properties to map to dates
 * @param  {string} format - (OPT) format of date property before map, default: 'd/m/Y'
 * @return {object}
 */
const mapPropsToDisplayDates = (object, props, format) => mapPropsToDates(object, props, format || DB_DATE_FORMAT, DISPLAY_DATE_FORMAT);

/**
 * Map specified properties in object to date of format 'd/m/Y'
 * @param  {object} object - object to map
 * @param  {array} props - properties to map to dates
 * @param  {string} format - (OPT) format of date property before map, default: 'Y-m-d H:i:s'
 * @return {object}
 */
const mapPropsToDbDates = (object, props, format) => mapPropsToDates(object, props, format || DISPLAY_DATE_FORMAT, DB_DATE_FORMAT);

// ----- Title Mapping Helpers -----//

const titleOptions = [
    { value: 0, label: 'Select Title' },
    { value: 1, label: 'Mr' },
    { value: 2, label: 'Mrs' },
    { value: 3, label: 'Ms' },
    { value: 4, label: 'Miss' },
    { value: 5, label: 'Dr' },
    { value: 7, label: 'Others' },
];

const mapTitleToValue = (title) => {
    const titleValue = titleOptions.find(option => option.label === title);
    return titleValue ? titleValue.value : title;
};

/**
 * Map title to label
 * @param  {number} title
 * @return {string}
 */
const mapTitleToLabel = (title) => {
    const titleLabel = titleOptions.find(option => option.value === parseInt(title, 10));
    return titleLabel ? titleLabel.label : title;
};

// ----- Local Storage Helpers -----//

/**
 * Get gridview filters stored in local storage
 * @param  {string} name - name to store filters under
 * @return {object}
 */
const getGridviewFilters = (name) => {
    let gridviewSearch = localStorage.getItem('gridviewSearch');
    if (gridviewSearch) {
        gridviewSearch = JSON.parse(gridviewSearch);
    }

    if (gridviewSearch && gridviewSearch[name]) {
        return gridviewSearch[name];
    }

    return {};
};

/**
 * Set gridview filters in local storage
 * @param {string} name - name to store filters under
 * @param {object} values - filter values to store
 */
const setGridviewFilters = (name, values) => {
    let gridviewSearch = localStorage.getItem('gridviewSearch');
    if (gridviewSearch) {
        gridviewSearch = JSON.parse(gridviewSearch);
    } else {
        gridviewSearch = {};
    }
    gridviewSearch[name] = values;
    localStorage.setItem('gridviewSearch', JSON.stringify(gridviewSearch));
};

// ----- Gridview Helpers -----//

/**
 * Handles setup of gridview
 * @param  {function} action - redux action to call with filters
 * @param  {string} storageName - name to get/store filters under in local storage
 * @param  {integer} page - default page number
 * @param  {integer} pageSize - default page size
 */
const handleBasicGridviewMount = (action, storageName, defaultValues, page, pageSize) => {
    let filters = getGridviewFilters(storageName);
    if (isEmpty(filters)) {
        filters = { page: page || 1, page_size: pageSize || 10 };
    }

    if (defaultValues) {
        filters = { ...filters, ...defaultValues };
    }

    setGridviewFilters(storageName, filters);
    action(filters);
};

/**
 * Handle searching of gridview
 * @param  {function} action - redux action to call with filters
 * @param  {string} storageName - name to get/store filters under in local storage
 * @param  {object} values - values to filter by
 * @param  {integer} page - default page number
 * @param  {integer} pageSize - default page size
 */
const handleBasicGridviewSearch = (action, storageName, values, page, pageSize) => {
    const filters = {
        ...values,
        page: page || 1,
        page_size: pageSize || 10,
    };
    setGridviewFilters(storageName, filters);
    action(filters);
};

/**
 * Handles clear search of gridview
 * @param  {function} action - redux action to call with filters
 * @param  {string} storageName - name to get/store filters under in local storage
 * @param  {integer} page - default page number
 * @param  {integer} pageSize - default page size
 */
const handleBasicGridviewClearSearch = (action, storageName, page, pageSize) => {
    const filters = { page: page || 1, page_size: pageSize || 10 };
    setGridviewFilters(storageName, filters);
    action(filters);
};

/**
 * Handles pagination of gridview
 * @param  {function} action - redux action to call with filters
 * @param  {string} storageName - name to get/store filters under in local storage
 * @param  {integer} page - default page number
 * @param  {integer} pageSize - default page size
 */
const handleBasicGridviewPagination = (action, storageName, page, pageSize) => {
    const filters = getGridviewFilters(storageName);
    filters.page = page;
    filters.page_size = pageSize;
    setGridviewFilters(storageName, filters);
    action(filters);
};

/**
 * Handles sort of gridview
 * @param  {function} action - redux action to call with filters
 * @param  {string} storageName - name to get/store filters under in local storage
 * @param  {string} value - column name to sort
 */
const handleBasicGridviewSort = (action, storageName, value) => {
    const filters = getGridviewFilters(storageName);
    filters.sort = value;
    setGridviewFilters(storageName, filters);
    action(filters);
};

// ----- Component Helpers -----//

/**
 * Get view button component
 * @param  {string|function} href
 */
const GridButton = ({ href, title }) => {
    const buttonConfig = {
        href,
        onClick: () => {},
        type: 'default',
    };

    if (typeof href === 'function') {
        buttonConfig.onClick = href;
        delete buttonConfig.href;
    }

    return <Button {...buttonConfig} className="ant-btn-xs">{title}</Button>;
};

GridButton.defaultProps = {
    title: 'View',
};

GridButton.propTypes = {
    href: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    title: PropTypes.string,
};

// ------------ //

export {
    // -- General Helpers -- //
    displayId,
    displayName,
    displayNumber,
    displayDate,
    checkDate,
    debounce,
    usePrevious,

    // -- Title Mapping Helpers -- //
    titleOptions,
    mapTitleToLabel,
    mapTitleToValue,

    // -- Array Mapping Helpers -- //
    addKeyProp,
    mapToOptions,

    // -- Object Mapping Helpers -- //
    mapPropsToInts,
    mapPropsBoolToInts,
    mapPropsToDisplayDates,
    mapPropsToDbDates,

    // -- Local Storage Helpers -- //
    getGridviewFilters,
    setGridviewFilters,

    // -- Gridview Helpers -- //
    handleBasicGridviewMount,
    handleBasicGridviewSearch,
    handleBasicGridviewClearSearch,
    handleBasicGridviewPagination,
    handleBasicGridviewSort,

    // ----- Component Helpers -----//
    GridButton,
};
