import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Button, notification } from 'antd';
import { each } from 'underscore';
import { connect } from 'react-redux';
import alertify from 'alertify.js';


import DOM from './doctorsPracticesSearch';

import {
    handleBasicGridviewMount,
    handleBasicGridviewSearch,
    handleBasicGridviewClearSearch,
    handleBasicGridviewPagination,
} from '../../../core/utils/helpers';

import * as customerActions from '../../../core/customer/customerActions';
import * as doctorActions from '../../../core/doctor/doctorActions';
import * as practiceActions from '../../../core/practice/practiceActions';

class DoctorsPracticesSearch extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;

        this.state = {
            doctorId: 0,
            practiceId: 0,
            practiceAddressId: 0,
        };
    }

    componentDidMount() {
        const { actions } = this.props;
        handleBasicGridviewMount(actions.getDoctorsRequest, 'searchDoctorsCustomer');
        handleBasicGridviewMount(actions.getPracticesRequest, 'searchPracticesCustomer');
    }

    // Render assign button based on sub table name
    getAssignButton = (table) => {
        let id = 0;
        if (table === 'practices') {
            id = this.state.practiceId;
        } else {
            id = this.state.doctorId;
        }

        return (
            <div className="d-flex justify-content-end mt-4">
                <Button type="primary" className="medium" disabled={!id} onClick={() => this.handleAssignDoctorPractice(table)}>Assign</Button>
            </div>
        );
    }

    // Set properties based on row (antd documentation)
    handleRow = (record, rowIndex, table) => {
        const { practiceId, doctorId } = this.state;
        let isSelected = false;
        if (table === 'doctors' && record.id === doctorId) {
            isSelected = true;
        } else if (table === 'practices' && record.id === practiceId) {
            isSelected = true;
        }

        return { className: isSelected ? 'row-selected' : '' };
    }

    // Handle checkbox change
    handleCheckboxChange = (state) => {
        this.setState(state);
    }

    // Handle gridview search
    handleSearch = (values) => {
        let isOkToSearch = false;
        each(values, (val) => {
            if (val.length >= 3) {
                isOkToSearch = true;
            }
        });

        if (!isOkToSearch) {
            alertify.error('Please enter at least 3 characters to search');
            return;
        }

        const { actions } = this.props;
        handleBasicGridviewSearch(actions.getDoctorsRequest, 'searchDoctorsCustomer', values);
        handleBasicGridviewSearch(actions.getPracticesRequest, 'searchPracticesCustomer', values);
    }

    // Handle gridview clear search
    handleClearSearch = (reset) => {
        const { actions } = this.props;
        handleBasicGridviewClearSearch(actions.getDoctorsRequest, 'searchDoctorsCustomer');
        handleBasicGridviewClearSearch(actions.getPracticesRequest, 'searchPracticesCustomer');
        reset();
    }

    // Handle sub table pagination for doctors
    handleDoctorsPagination = (page, pageSize) => {
        const { actions } = this.props;
        handleBasicGridviewPagination(actions.getDoctorsRequest, 'searchCustomers', page, pageSize);
    }

    // Handle sub table pagination for practices
    handlePracticesPagination = (page, pageSize) => {
        const { actions } = this.props;
        handleBasicGridviewPagination(actions.getPracticesRequest, 'searchCustomers', page, pageSize);
    }

    // Handle assign button click
    handleAssignDoctorPractice = (table) => {
        // const { actions, customerId, toggleModal } = this.props;
        const { actions, customerId, toggleModal, customerPractice } = this.props;
        const { practiceId, practiceAddressId, doctorId } = this.state;

        // If assigning practice
        if (table === 'practices' && practiceId) {
            actions.updateCustomerPracticeRequest({
                id: customerId,
                practice_id: practiceId,
                practice_address_id: practiceAddressId
            });
        }

        // If assigning doctor
        if (table === 'doctors' && doctorId) {
            // Ensure a practice has been assigned to the customer first
            if (!customerPractice || !customerPractice.id) {
                notification.error({ message: 'Please assign a practice to the customer before assigning a doctor' });
                return;
            }
            actions.updateCustomerDoctorRequest({ id: customerId, doctor_id: doctorId });
        }

        // Close modal
        toggleModal(false);
    }

    render() {
        return this.view();
    }
}

DoctorsPracticesSearch.defaultProps = {
    toggleModal: () => {},
    customerPractice: null
};

DoctorsPracticesSearch.propTypes = {
    actions: PropTypes.object.isRequired,
    customerId: PropTypes.number.isRequired,
    toggleModal: PropTypes.func,
    customerPractice: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        doctors: state.doctor.doctors.rows,
        doctorsPage: state.doctor.doctors.page,
        doctorsTotal: state.doctor.doctors.total,
        practices: state.practice.practices.rows,
        practicesPage: state.practice.practices.page,
        practicesTotal: state.practice.practices.total,
        customerPractice: state.customer.practice,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...customerActions,
            ...doctorActions,
            ...practiceActions,
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DoctorsPracticesSearch);
