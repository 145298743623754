import React from 'react';

import Gridview from '../../../elements/Gridview';
import { displayId, displayNumber, displayDate } from '../../../core/utils/helpers';

export default function () {
    const { products, total, page, isFetching } = this.props;
    const config = [
        { dataIndex: 'sku', title: 'Product Code', render: value => displayId(value.toString()) },
        { dataIndex: 'title', title: 'Product Name' },
        { dataIndex: 'description', title: 'Description' },
        { dataIndex: 'times_ordered', title: 'Times Ordered', className: 'text-center', render: displayNumber },
        { dataIndex: 'last_dispatched', title: 'Last dispatched', render: value => displayDate(value) },
    ];

    return (
        <Gridview
            config={config}
            data={products}
            loading={isFetching}
            title="Historcal Products"
            pagination={{
                simple: true,
                onChange: this.handlePagination,
                pageSize: 10,
                total,
                current: page,
                className: 'custom-pagination',
            }} />
    );
}
