import React from 'react';
import { find } from 'underscore';

import Gridview from '../../../elements/Gridview';
import Icon from '../../../elements/Icon';
import { displayId, displayDate, GridButton } from '../../../core/utils/helpers';

const { FULL_ORDER_STATUS_OPTIONS } = require('../../../core/utils/options').default;

export default function () {
    const { orders, total, page, isFetching } = this.props;

    const renderOrderStatus = (value) => {
        const numericValue = typeof value === 'string' ? Number(value) : value;
        // const option = find(FULL_ORDER_STATUS_OPTIONS, item => item.value === value);
        const option = find(FULL_ORDER_STATUS_OPTIONS, item => item.value === numericValue);
        return option ? option.label : '-';
    };

    const config = [
        { dataIndex: 'id', title: 'Order ID', render: value => displayId(value.toString()) },
        { dataIndex: 'is_auto_order', title: 'Auto-order', className: 'text-center', render: (text, record) => <Icon name="auto-order" visible={record.is_auto} /> },
        { dataIndex: 'order_status', title: 'Status', render: renderOrderStatus },
        { dataIndex: 'order_date', title: 'Dispatch date', render: value => displayDate(value) },
        { dataIndex: 'key', align: 'right', render: (text, record) => <GridButton href={`/order/${record.id}`} /> },
    ];

    return (
        <Gridview
            config={config}
            data={orders}
            loading={isFetching}
            title="Historical Orders"
            pagination={{
                simple: true,
                onChange: this.handlePagination,
                pageSize: 10,
                total,
                current: page,
                className: 'custom-pagination',
            }} />
    );
}
