import React from 'react';
import { Button, Select, Form as AntForm } from 'antd';

import Icon from '../../../elements/Icon';

const AntItem = AntForm.Item;
const Option = Select.Option;

export default function () {
    const { customers, changeVisible } = this.props;
    const { customerError } = this.state;

    // Form must be ant form due to select search,
    // as the select component must be called within this render to re render
    // however the redux field component property must be a class or function,
    // this function must be called as `this.function` to keep component focus
    // which means the select component won't be re rendered when fetching the options
    return (
        <AntForm>

            <div className="mb-2">
                <AntItem label="Name or ID of customer">
                    <div className={customerError ? 'has-error' : ''}>
                        <Select
                            value={this.state.customerId || undefined}
                            placeholder={'Type three or more characters to search'}
                            onChange={this.handleChangeCustomers}
                            onSearch={this.handleSearchCustomers}
                            filterOption={false}
                            showSearch
                            suffixIcon={<Icon name="arrow-down" className="anticon anticon-down ant-select-arrow-icon" />}>
                            {customers.map(item => <Option key={`select-customer-${item.id}`} value={item.id}>#{item.id} - {item.first_name} {item.last_name}</Option>)}
                        </Select>
                        {customerError ? <span className="ant-form-explain">Required</span> : false}
                    </div>
                </AntItem>
                <div className="info-span">(Adding a customer which is already assigned to a nurse will remove their current nurse)</div>
            </div>

            <div className="text-center mt-2">
                <Button type="primary" className="wide" onClick={this.handleAddCustomerNurseSubmit}>Add to nurse</Button>
                <div>
                    <Button className="link-green btn-empty underlined mt-1 mb-1" onClick={() => changeVisible(false)}>
                        cancel
                    </Button>
                </div>
            </div>
        </AntForm>
    );
}
